import {useForm} from "antd/es/form/Form";
import {Card, Col, Divider, Form, Input, message, Row, Select, Typography} from "antd";
import React, {useEffect, useImperativeHandle, useState} from "react";
import {API} from "aws-amplify";
import TypographyCurrency from "../CurrencySelect/TypographyCurrency";
import TypographyCountry from "../CurrencySelect/TypographyCountry";

const BeneficiaryModal = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        submit: () => form.submit(),
        setFields: (value) => form.setFieldsValue(value),
        resetFields: () => form.resetFields()
    }))

    const [form] = useForm();
    const [componentState, setComponentState] = useState({
        countries: [],
        currencies: [],
        bank_address_country: null,
        account_currency: null
    });
    const [fields, setFields] = useState({
        show_iban: false,
        show_bsb: false,
        show_sort_code: false,
        show_swift_code: true,
        show_aba: false,
        show_account_number: true,
        show_institution_number: false,
        show_transit_code: false,
        show_bank_code: false,
    });

    const setFieldVisibility = (key, value, countries) => {
        const newState = {};
        if (key === 'bank_address_country') {
            componentState.countries.forEach(item => {
                if (item.id === value) {
                    newState.show_swift_code = true;
                    newState.show_account_number = true;
                    newState.show_iban = item.use_iban === 1;
                    newState.show_bsb = item.use_bsb === 1;
                    newState.show_aba = item.use_aba === 1;
                    newState.show_sort_code = item.use_sort_code === 1;
                    newState.show_account_number = item.use_account_number === 1;
                    newState.show_institution_number = item.use_institution_number === 1;
                    newState.show_transit_code = item.use_transit_code === 1;
                    newState.show_bank_code = item.use_bank_code === 1;
                }
            });
        }
        return newState;
    }

    const handleSubmit = (values) => {
        if (props.clientId) {
            values.client_id = props.clientId;
        }
        const loader = message.loading("Saving Beneficiary. Please wait..");
        API.post("commons", "/insert", {
            body: {
                context: 'beneficiaries',
                data: values
            }
        }).then((res) => {
            message.success("Beneficiary Saved..");
            if (props.onSubmit) {
                props.onSubmit(res);
            }
        })
            .catch(err => {
                console.log(err);
                message.error("Failed to save Beneficiary. Please try again");
            }).finally(() => loader());
    }

    useEffect(() => {
        Promise.all([
            API.post("commons", "/fetch", {
                body: {
                    context: 'countries',
                    fields: ['*'],
                    condition: {deleted: false},
                }
            }),
            API.post("commons", "/fetch", {
                body: {
                    context: 'currencies',
                    fields: ['*'],
                    condition: {deleted: false},
                }
            })
        ]).then(([countries, currencies]) => {
            setComponentState(prev => ({...prev, currencies: currencies, countries: countries}))
        });
    }, []);

    return <Form initialValues={{ account_currency: 5 }}
                 labelAlign={'left'}
                 labelCol={{ span: 8 }}
                 wrapperCol={{span: 16}}
                 name={'form'}
                 form={form}
                 onFinish={handleSubmit}>
        <Card size={'small'} title={'Beneficiary Details'} style={{ marginBottom: '10px' }}>
            <Typography.Paragraph strong={true}>Provide details here of the individual or entity
                that
                will receive the funds.</Typography.Paragraph>
            <Form.Item name="account_currency" label={'Currency'} rules={[{required: true, message: 'Currency is required'}]}>
                <Select optionFilterProp={'alt'}
                        defaultValue={props.activeCurrency || null}
                        showSearch={true}
                        style={{minWidth: '100px'}}
                        onChange={(val) => setComponentState(prev => ({
                            ...prev,
                            account_currency: val
                        }))}
                        options={componentState.currencies.map(i => ({
                            ...i,
                            value: i.id,
                            alt: i.iso_alpha_3,
                            label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                        }))}></Select>
            </Form.Item>
            <Form.Item name="bank_address_country" label={'Country'} rules={[{required: true, message: 'Bank is required'}]}>
                <Select optionFilterProp={'alt'}
                        showSearch={true}
                        style={{minWidth: '100px'}}
                        onChange={(val) => {
                            setComponentState(prev => ({...prev, bank_address_country: val}));
                            const fieldState = setFieldVisibility('bank_address_country', val, componentState.countries);
                            setFields(fieldState);
                        }}
                        options={componentState.countries.map(i => ({
                            ...i,
                            value: i.id,
                            alt: i.iso_alpha_3,
                            label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                        }))}></Select>
            </Form.Item>
        </Card>
        {
            componentState.account_currency && componentState.bank_address_country && <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card size={'small'} title={'Beneficiary Details'}>
                        <Typography.Paragraph strong={true}>Provide details here of the individual or entity
                            that
                            will receive the funds.</Typography.Paragraph>
                        <Divider style={{marginTop: 0}}/>
                        <Form.Item name="ben_legal_name" label={'Beneficiary Name'}
                                   rules={[{required: true, message: 'Beneficiary name is required'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="ben_address_country" label={'Country'}>
                            <Select optionFilterProp={'alt'}
                                    placeholder={' -- SELECT BANK COUNTRY --'}
                                    showSearch={true}
                                    allowClear={true}
                                    options={componentState.countries.map(i => ({
                                        ...i,
                                        label: <TypographyCountry
                                            iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>,
                                        alt: `${i.full_name}${i.iso_alpha_3}`,
                                        value: i.id
                                    }))}></Select>
                        </Form.Item>
                        <Form.Item label={'Address Line'} name={'ben_address_line_1'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'Suburb / Region'} name={'ben_address_suburb'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'Post Code'} name={'ben_address_postcode'}>
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card size={'small'} title={'Bank Details'}>
                        <Typography.Paragraph strong={true}>These details are for the Bank account that you have
                            been given to pay funds to the beneficiary specified on the left. Please also
                            provide
                            the address of the bank.</Typography.Paragraph>
                        <Divider style={{marginTop: 0}}/>
                        <Form.Item label={'Bank name'} name={'bank_legal_name'}>
                            <Input placeholder={'Enter Bank Name'}/>
                        </Form.Item>

                        {
                            fields.show_iban &&
                            <Form.Item label={'IBAN'} name={'iban'}>
                                <Input placeholder={'Enter IBAN'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_swift_code &&
                            <Form.Item label={'Swift Code'} name={'swift_code'}>
                                <Input placeholder={'Enter Swift Code'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_aba &&
                            <Form.Item name="aba_routing_number" label={'ABA Routing Number'}>
                                <Input placeholder={'Enter ABA Routing Number'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_sort_code &&
                            <Form.Item name="sort_code" label={'Sort Code'}>
                                <Input placeholder={'Sort Code'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_bsb &&
                            <Form.Item name="bsb_code" label={'BSB'}>
                                <Input placeholder={'Enter BSB'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_transit_code &&
                            <Form.Item name="transit_code" label={'Transit Code'}>
                                <Input placeholder={'Enter Transit Code'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_institution_number &&
                            <Form.Item name="institution_number" label={'Institution Number'}>
                                <Input placeholder={'Enter Institution Number'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_bank_code &&
                            <Form.Item name="bank_code" label={'Bank Code'}>
                                <Input placeholder={'Enter Bank Code'}/>
                            </Form.Item>
                        }
                        {
                            fields.show_account_number &&
                            <Form.Item label={'Account Number'} name={'account_number'}>
                                <Input placeholder={'Enter Account Number'}/>
                            </Form.Item>
                        }
                        <Form.Item label={'Address Line'} name={'bank_address_line_1'}>
                            <Input placeholder={'Enter Bank Address'}/>
                        </Form.Item>
                        <Form.Item label={'Suburb'} name={'bank_address_suburb'}>
                            <Input placeholder={'Enter Suburb'}/>
                        </Form.Item>
                        <Form.Item label={'Post Code'} name={'bank_address_postcode'}>
                            <Input placeholder={'Enter Post Code'}/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        }
    </Form>
});

export default BeneficiaryModal;