import { combineReducers } from 'redux'

import beneficiaries from './beneficiaries'
import transfers from './transfers'
import forwards from './forwards'
import transfers_2 from './transfers_2'
import transfers_dashboard from './transfers_dashboard'
import transfers_pending from './transfers_pending'
import news from './news'
import identification from './identification'
import payments from './payments'
import language from './language'
import transfers_dashboard_multiple from './transfers_dashboard_multiple'
import registration from './registration'

import {
    UPDATE_SRC,
    UPDATE_CURRENT_BRAND,
    UPDATE_CURRENT_USER,
    UPDATE_CURRENT_USER_ID,
    UPDATE_CURRENT_TEAM,
    UPDATE_CURRENT_USER_LOG_OUT,
    UPDATE_ECONOMIC_CALENDAR,
} from '../actions'
import {countries} from "./countries";
import {currencies} from "./currencies";

const initialState = {
    src: null,
    current_brand: null,
    current_user_id: null,
    current_client_id: null,
    current_client_aws_cognito_id: null,
    show_economic_calendar: true,
    redirect_to_registration: true,
    currency_list: [],
    country_list: []
}

function app_state(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ECONOMIC_CALENDAR:
            return Object.assign({}, state, {
                show_economic_calendar: action.value,
            })
        case UPDATE_SRC:
            return Object.assign({}, state, {
                src: action.src,
            })

        case UPDATE_CURRENT_BRAND:
            return Object.assign({}, state, {
                current_brand: action.brand,
            })

        case UPDATE_CURRENT_USER:
            return Object.assign({}, state, {
                current_user_id: action.aws_cognito_id,
                current_client_id: action.aws_cognito_id,
                current_client_aws_cognito_id: action.aws_cognito_id,
                current_client: action.current_client_record,
                current_brand: action.brand,
                current_division: action.current_division,
                current_team: action.current_team,
                current_team_fees: action.current_team_fees,
                current_domain: action.current_domain,
                country_list: action.country_list,
                currency_list: action.currency_list,
            })

        case UPDATE_CURRENT_TEAM:
            return Object.assign({}, state, {
                current_team: action.current_team,
            })

        case UPDATE_CURRENT_USER_ID:
            return Object.assign({}, state, {
                current_user_id: action.aws_cognito_id,
                current_client_id: action.aws_cognito_id,
                current_client_aws_cognito_id: action.aws_cognito_id,
            })

        case UPDATE_CURRENT_USER_LOG_OUT:
            return Object.assign({}, state, {
                current_user_id: null,
                current_client_id: null,
                current_client_aws_cognito_id: null,
            })

        default:
            return state
    }
}

const rootReducer = combineReducers({
    app_state,
    countries,
    currencies,
    beneficiaries,
    transfers,
    forwards,
    news,
    identification,
    payments,
    language,
    transfers_2,
    transfers_dashboard,
    transfers_dashboard_multiple,
    transfers_pending,
    registration,
})

export default rootReducer
