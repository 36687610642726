import React from "react";
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import { API, Storage } from "aws-amplify";
import { injectIntl } from "react-intl";
import {
  change_current_step,
  clearNewTransfer,
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary,
  createNewTransfer
} from "../../redux/actions/transfers_dashboard";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../views/Pages/DashboardPageStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Slide from "@material-ui/core/Slide";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";

import Table from "components/Table/Table.jsx";

import { Alert, Space } from 'antd';

//core for react-intl
import { FormattedMessage } from "react-intl";
import { VectorMap } from "react-jvectormap";
import DashboardTransferStep1 from "./DashboardTransferStep1";
import DashboardTransferStep2 from "./DashboardTransferStep2";
import DashboardTransferStep3 from "./DashboardTransferStep3";
// import EconomicHoliday from "../EconomicInformation/EconomicHoliday";
import { CustomDrawer } from "../Slider";

const axios = require("axios");


// import { Popconfirm } from 'antd';
// import {  notification } from 'antd';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const moment = require("moment");

class DashboardTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 1,
      firstStepDisabled: false,
      // economic_info_modal_open: false,
      // economic_info_modal_title: "",
      secondStepFunction: null,
      fetchNewRate: null,
      time: {},
      seconds: 1,
      toggleDrawer: true,
      drawerInfo: null,
      // transferConfirmationDrawer: false,
      bank: {
        deleted: 0,
        full_name: "",
        id: 0,
        iso_alpha_2: "",
        iso_alpha_3: "",
        list_priority: 1,
        un_code: "036",
        use_aba: null,
        use_account_number: 1,
        use_bank_code: null,
        use_bsb: 1,
        use_iban: null,
        use_sort_code: null,
        use_swift: 1,
        use_transit_code: null
      },
      transferSubmitting: false
    };
    this.timer = 0;
    this.transferConfirmationDrawer = false;
    this.go = false;
  }

  componentDidMount() {}

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.prefill !== this.props.prefill) {
      this.props.clearNewTransfer();
    }
    if (
      this.props.transfers_dashboard &&
      this.props.transfers_dashboard.transfer_detail_id
    ) {
      let transferId = this.props.transfers_dashboard.transfer_detail_id
      this.props.clearNewTransfer();

      this.props.history.push(`/transfers/edit/${transferId}?tab=remittance`);
    }
  }

  componentWillUnmount() {
    this.props.clearNewTransfer();
  }

  isValidated(step) {
    let return_flag = true;
    const transferData = this.props.transfers_dashboard.new_transfer_data;
    if (step === 1) {
      let beneficiary_array = transferData.multiple_beneficiary_id || [{}];
      if (transferData.currency_from_state !== "success") {
        // console.log('currency_from_state - error')
        this.props.updateNewTransferCreation("currency_from_state", "error");
        return_flag = false;
      }

      // Allow transfers to be the same currency
      // if((transferData.currency_from_id === transferData.currency_to_id) && !transferData.ledgerFrom) {
      //   console.log('currency_from_id === currency_to_id - error')
      //   return_flag = false;
      // }
      
      if (transferData.currency_to_state !== "success") {
        // console.log('currency_to_state - error')
        this.props.updateNewTransferCreation("currency_to_state", "error");
        return_flag = false;
      }
      if (transferData.amount_from_state !== "success") {
        // console.log('amount_from_state - error')
        this.props.updateNewTransferCreation("amount_from_state", "error");
        return_flag = false;
      }
      if (transferData.amount_to_state !== "success") {
        // console.log('amount_to_state - error')
        this.props.updateNewTransferCreation("amount_to_state", "error");
        return_flag = false;
      }
      if (
        !transferData.ledgerTo &&
        transferData.multiple_beneficiary_id[0].beneficiary_id_state !==
          "success" &&
        transferData.multiple_beneficiary_id[0].checked_beneficiary_details !==
          true
      ) {
        beneficiary_array[0].beneficiary_id_state = "error";
        // console.log('beneficiary_id_state - error')
        this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
        return_flag = false;
      }
      if (
        transferData.multiple_beneficiary_id[0]
          .purpose_of_payment_detail_state !== "success"
      ) {
        beneficiary_array[0].purpose_of_payment_detail_state = "error";
        // console.log('purpose_of_payment_detail_state - error')
        this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
        return_flag = false;
      }
      if (
        transferData.multiple_beneficiary_id[0].purpose_of_payment_detail === 1
      )
        if (
          transferData.multiple_beneficiary_id[0]
            .purpose_of_payment_other_state !== "success"
        ) {
          beneficiary_array[0].purpose_of_payment_other_state = "error";
          // console.log('purpose_of_payment_other_state - error')
          this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
          return_flag = false;
        }
    }
    return return_flag;
  }
  secondsToTime = secs => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  startTimer = () => {

    // if (this.transferConfirmationDrawer) {
      this.go = true;
    // }
    // if (this.props.transfers_dashboard.current_step === 2) {
    //   this.go = true;
    // }

    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };
  stopTimer = () => {
    this.go = false;
    this.setState({
      // seconds: 0,
      seconds: 1,
      time: 0
    });
    clearInterval(this.timer);
    this.timer = 0;
    // this.timer = null;
  };

  countDown = () => {
    let seconds = this.state.seconds - 1;
    if (this.go) {
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds
      });

      if (seconds === 0) {
        this.fetchNewRate();
        this.setState({
          seconds: 30
        });
      }
    }
  };

  getNextBusinessDay(d, n) {
    d = new Date(d.getTime());
    var day = d.getDay();
    d.setDate(
      d.getDate() +
        n +
        (day === 6 ? 2 : +!day) +
        Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2
    );
    return d;
  }

  fetchNewRate = () => {
    let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
    axios
      .get(url, {
        params: {
          currencies: this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3,
          source: this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3,
          format: "1"
        }
      })
      .then(response => {
        let key = `${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}`;
        let rate_string = response.data.quotes[key];

        // let amount_to = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_to);
        let rate = Number.parseFloat(rate_string);
        let rate_adjust =
          Number.parseFloat(this.props.app_state.current_client.default_rate) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_division) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_entity) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_team) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;

        // // Add Credit Card Rate Adjustment
        // if (
        //   this.props.transfers_dashboard.new_transfer_data.payment_method ===
        //   "worldpay"
        // ) {
        //   adjusted_rate = adjusted_rate - adjusted_rate * 0.02;
        // }

        if (
          this.props.transfers_dashboard.new_transfer_data.currency_from_id ==
          this.props.transfers_dashboard.new_transfer_data.currency_to_id
        ) {
          // console.log("same currency")
          // adjusted_rate = rate;
          adjusted_rate = 1;

        }

        // handle to_amount
        if (
          this.props.transfers_dashboard.new_transfer_data.amount_lock ===
          "from_amount"
        ) {
          let amount_from = Number.parseFloat(
            this.props.transfers_dashboard.new_transfer_data.amount_from
          );
          let amount_to = amount_from * adjusted_rate;
          this.props.updateNewTransferCreation(
            "amount_to",
            amount_to.toFixed(2)
          );
        }

        // handle from_amount
        if (
          this.props.transfers_dashboard.new_transfer_data.amount_lock ===
          "to_amount"
        ) {
          let amount_to = Number.parseFloat(
            this.props.transfers_dashboard.new_transfer_data.amount_to
          );
          let amount_from = amount_to / adjusted_rate;
          this.props.updateNewTransferCreation(
            "amount_from",
            amount_from.toFixed(2)
          );
        }
        this.props.updateNewTransferCreation("client_rate", adjusted_rate);
      })
      .catch(error => {
        console.log(error);
      });
  };


  switch_to_step1() {
    this.setState({
      current_step: 1
    });
    this.props.change_current_step(1);
  }

  switch_to_next_change_current_step() {
    if (this.state.current_step === 1) {
      if (this.isValidated(this.state.current_step)) {
        // notification['warning']({
        //   message: 'Fee Applied',
        //   description:
        //     'A fee of $10 has been applied to this transfer.',
        //     placement: "top",
        //     duration: 0,
        // });

        this.setState({
          current_step: 2
        });
        this.props.change_current_step(2);
      }
    } else if (this.state.current_step === 2) {
      // this.setState({
      //   current_step: 3
      // });
      // this.props.change_current_step(3);
    }
  }

  switch_to_previous_change_current_step() {
    if (this.state.current_step === 2) {
      this.setState({
        current_step: 1
      });
      this.props.change_current_step(1);
    } else if (this.state.current_step === 3) {
      this.setState({
        current_step: 2
      });
      this.props.change_current_step(2);
    }
  }

  handleClearValues = () => {
    // this.props.clearNewTransfer();
    window.location.reload();
  }

  handleProceedStep1 = async () => {
    if (this.state.current_step === 1) {
      if (this.isValidated(this.state.current_step)) {
        this.transferConfirmationDrawer = true
        this.startTimer();

        // Only update a remitting bank account IF the currencies are different
        if (this.props.transfers_dashboard.new_transfer_data.currency_to.id != 
          this.props.transfers_dashboard.new_transfer_data.currency_from.id) {

            const remittingAccountDetails = await API.post(
              "transfers",
              "/remitting-account/get-details-thresh",
              {
                body: {
                  currencyId: this.props.transfers_dashboard.new_transfer_data.currency_from.id,
                  amountFrom: this.props.transfers_dashboard.new_transfer_data.amount_from,
                  teamId: this.props.app_state.current_team.id,
                  clientId: this.props.app_state.current_client.id
                }
              }
            )
              .then(response => {
                return response;
              })
              .catch(error => {
                console.log(error);
              });
        
            this.props.updateNewTransferCreation(
              "remitting_bank_account_detail",
              remittingAccountDetails.entityBanksAccountResults
            );
            this.props.updateNewTransferCreation(
              "remitting_bank_detail",
              remittingAccountDetails.entityBanksResults
            );

          }

        this.state.toggleConfirmation()
      }
    }
  }

  handleConfirmationProceed = () => {
    console.log('handleConfirmationProceed - click')
    this.transferConfirmationDrawer = false
    this.stopTimer();
    this.go = false;

  }
  
  handleConfirmationFail = () => {
    console.log('handleConfirmationFail - click')
    this.transferConfirmationDrawer = false
    this.stopTimer();
    this.go = false;
    this.state.toggleConfirmation()
  }

  renderMapComponent() {
    return (
      <VectorMap
        map={"world_mill"}
        onRegionClick={(e, code) => {
          const mapData = this.props.transfers_dashboard.map_data;
          if (mapData[code] != undefined) {
            this.state.toggleDrawer();
            const bank = this.props.app_state.country_list.find(
              x => x.iso_alpha_2 == code
            );
            this.setState({
              bank: bank
            });
          }
        }}
        onRegionOver= {(e, el, code) => {
          e.preventDefault();
        }}
        onRegionLabelShow= {(e, el, code) => {
          e.preventDefault();
        }}
        backgroundColor="transparent"
        zoomOnScroll={false}
        containerStyle={{
          width: "100%",
          height: "420px"
        }}
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0
          },
          hover: {
            "fill-opacity": 1
        }
        }}
        series={{
          regions: [
            {
              values: this.props.transfers_dashboard.map_data,
              scale: ["#26c6da", "#4caf50"],
              normalizeFunction: "polynomial"
            }
          ]
        }}
      />
    );
  }

  render_economic_information = () => {};

  // handle_economic_info_modal_close = () => {
  //   this.setState({
  //     economic_info_modal_open: false
  //   });
  // };

  // handle_economic_info_modal_open = info_choice => {
  //   let economic_info_modal_title = "";
  //   switch (info_choice) {
  //     case "economic_info":
  //       economic_info_modal_title = "Economic Data Releases";
  //       break;
  //     case "economic_holidays":
  //       economic_info_modal_title = "Economic Holidays";
  //       break;
  //     case "fx_charts":
  //       economic_info_modal_title = "FX Charts";
  //       break;
  //   }
  //   this.setState({
  //     economic_info_modal_open: true,
  //     economic_info_modal_title,
  //     economic_info_choice: info_choice
  //   });
  // };

  // get_economic_info_modal = () => {
  //   switch (this.state.economic_info_choice) {
  //     case "economic_holidays":
  //       return <EconomicHoliday />;
  //   }
  // };

  onClickSecondStep = () => {
    this.state.secondStepFunction();
  };
  handle_new_transfer = () => {
    this.props.clearNewTransfer();
    this.switch_to_step1();
  };

  getInstructions = () => {
    const iban = this.state.bank.show_iban ? <li>IBAN</li> : '';
    const swift = this.state.bank.use_swift ? <li>SWIFT Code</li> : ''
    const aba = this.state.bank.use_aba ? <li>ABA Routing Number</li> : ''
    const sort_code = this.state.bank.use_sort_code ? <li>Sort Code</li> : ''
    const bsb = this.state.bank.use_bsb ? <li>BSB</li> : ''
    const transit_code = this.state.bank.use_transit_code ? <li>Transit Code</li> : ''
    const instution_number = this.state.bank.use_institution_number ? <li>institution Number</li> : ''
    const bank_code = this.state.bank.use_bank_code ? <li>Bank Code</li> : ''
    const account_number = this.state.bank.use_account_number ? <li>Account Number</li> : ''
    return (
      <GridContainer>
        {this.state.bank.full_name && (
          <GridItem xs={12} sm={12}>
            <p>
              When transfering to &nbsp;
              <img
                src={
                  this.state.bank.iso_alpha_2 !== ""
                    ? require(`../../assets/img/flags/${
                        this.state.bank.iso_alpha_2
                      }.png`)
                    : ""
                }
              />
              &nbsp;
              <strong>{this.state.bank.full_name}</strong>, we require
            </p>
            <ul>
              <li>Bank name</li>
                {iban}
                {swift}
                {aba}
                {sort_code}
                {bsb}
                {transit_code}
                {instution_number}
                {bank_code}
                {account_number}
            </ul>
          </GridItem>
        )}
      </GridContainer>
    );
  };

  getConfirmationContent = () => {

    if (
      !this.props.transfers_dashboard.new_transfer_data.currency_from ||
      !this.props.transfers_dashboard.new_transfer_data.currency_to || 
      !this.props.transfers_dashboard.new_transfer_data.amount_from || 
      !this.props.transfers_dashboard.new_transfer_data.amount_to
    ) {
      return null;
    }

    // let feeWarningText;
    let amountFromDisplay = this.props.transfers_dashboard.new_transfer_data.amount_from;
    // if (this.props.transfers_dashboard.new_transfer_data.feeAmount) {
    //   amountFromDisplay = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_from) + Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.feeAmount);
    //   feeWarningText = `(including ${this.props.transfers_dashboard.new_transfer_data.feeAmount} fee)`
    // }

    let request_summary = `You sell
      ${this.props.intl.formatNumber(
        amountFromDisplay,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(
        this.props.transfers_dashboard.new_transfer_data.amount_to,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      )} ${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `;

      const { classes } = this.props;
    return (
      <>

      <GridContainer style={{marginTop: 48}}>
        <GridItem xs={12}>
          <h4 style={{color: 'white'}}>Please Confirm Details</h4>
        </GridItem>
        <GridItem>
          <Table
            style={{color: 'white'}}
            tableData={[
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  You Sell
                </span>,
                <React.Fragment>
                  <div
                    style={{color: 'white'}}
                    className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                  />
                  {` `}
                  <span style={{fontWeight: 600, color: 'white'}}>
                  {this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{` `}
                  {this.props.intl.formatNumber(
                    amountFromDisplay,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                  </span>
                  {/* {feeWarningText && (
                            <>
                            <br/>
                            <span style={{color: 'white'}}>{feeWarningText}</span>
                            </>
                        )} */}
                  {this.props.transfers_dashboard.new_transfer_data.ledgerFrom && (
                    <React.Fragment>
                      <br />
                      <span style={{fontWeight: 600, color: 'white'}}>
                        This will be deducted from your ledger balance
                      </span>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ],
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  You Buy
                </span>,
                <React.Fragment>
                  <div
                    className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                  />
                  {` `}
                  <span style={{fontWeight: 600, color: 'white'}}>
                  {this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{` `}
                  {this.props.intl.formatNumber(
                    this.props.transfers_dashboard.new_transfer_data.amount_to,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                  </span>
                </React.Fragment>
              ],
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  Exchange Rate
                </span>,
                <React.Fragment>
                  <span style={{fontWeight: 600, color: 'white'}}>
                  {this.props.intl.formatNumber(
                    this.props.transfers_dashboard.new_transfer_data
                      .client_rate,
                    {
                      minimumFractionDigits: 5,
                      maximumFractionDigits: 5
                    }
                  )}
                  </span>
                </React.Fragment>
              ],
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  Beneficiary Name
                </span>,
                <React.Fragment>
                  {this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].checked_beneficiary_details ===
                  true ? (
                    <React.Fragment>To Be Advised</React.Fragment>
                  ) : (
                    <React.Fragment>
                      {this.props.transfers_dashboard.new_transfer_data
                        .send_to_holding_acc_checkbox ? (
                        <React.Fragment>
                          <span style={{fontWeight: 600, color: 'white'}}>
                            {this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{" "}
                            {this.props.intl.formatNumber(
                              this.props.transfers_dashboard.new_transfer_data
                                .amount_to,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )} &nbsp;
                            will be added into your {this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()} currency balance
                          </span>
                        </React.Fragment>
                      ) : this.props.transfers_dashboard.new_transfer_data
                      .multiple_beneficiary_id[0].beneficiary ? (
                        <React.Fragment>
                          <span style={{fontWeight: 600, color: 'white'}}>
                            {this.props.transfers_dashboard.new_transfer_data
                              .multiple_beneficiary_id[0].beneficiary
                              .ben_legal_name}
                          </span> 
                        </React.Fragment>
                      ) : (<></>)}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ],
              (this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary &&  
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary.bank_legal_name) ? 
                [
                  <span style={{fontWeight: 800, color: 'white'}}>
                    Bank Name
                  </span>,
                  <span style={{fontWeight: 600, color: 'white'}}>
                  {
                    this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary.bank_legal_name
                  }
                  </span>
                ] : [],
                (this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].beneficiary &&  
                  this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].beneficiary.iban) ? 
                [
                  <span style={{fontWeight: 800, color: 'white'}}>
                    IBAN
                  </span>,
                  <span style={{fontWeight: 600, color: 'white'}}>
                  {
                    this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary.iban
                  }
                  </span>
                ] : [],
                (this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].beneficiary &&  
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary.aba_routing_number) ? 
                [
                  <span style={{fontWeight: 800, color: 'white'}}>
                    ABA Routing
                  </span>,
                  <span style={{fontWeight: 600, color: 'white'}}>
                  {
                    this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary.aba_routing_number
                  }
                  </span>
                ] : [],
              (this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary &&  
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary.bsb_code) ? 
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  BSB Code
                </span>,
                <span style={{fontWeight: 600, color: 'white'}}>
                {
                  this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].beneficiary.bsb_code
                }
                </span>
              ] : [],
              (this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary &&  
                this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary.sort_code) ? 
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  Sort Code
                </span>,
                <span style={{fontWeight: 600, color: 'white'}}>
                {
                  this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].beneficiary.sort_code
                }
                </span>
              ] : [],
              (this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].beneficiary &&  
                this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].beneficiary.account_number) ? 
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  Account Number
                </span>,
                <span style={{fontWeight: 600, color: 'white'}}>
                {
                  this.props.transfers_dashboard.new_transfer_data
                  .multiple_beneficiary_id[0].beneficiary.account_number
                }
                </span>
              ] : [],
              (this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].beneficiary &&  
            this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].beneficiary.swift_code) ? 
          [
            <span style={{fontWeight: 800, color: 'white'}}>
              SWIFT Code
            </span>,
            <span style={{fontWeight: 600, color: 'white'}}>
            {
              this.props.transfers_dashboard.new_transfer_data
              .multiple_beneficiary_id[0].beneficiary.swift_code
            }
            </span>
          ] : [],
          [
                <span style={{fontWeight: 800, color: 'white'}}>
                  Purpose of Payment
                </span>,
                <span style={{fontWeight: 600, color: 'white'}}>
                {
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0]
                    .purpose_of_payment_detail_nickname
                }
                </span>
              ],
              this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].detail_1 ? 
              [
                <span style={{fontWeight: 800, color: 'white'}}>
                  Reference/Invoice
                </span>,
                <span style={{fontWeight: 600, color: 'white'}}>
                {
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].detail_1
                }
                </span>
              ] : [],
            ]}
          />
        </GridItem>
        </GridContainer>
        <GridContainer style={{marginTop: 48}}>
        <GridItem xs={12}>
          <h4 style={{color: 'white'}}>Press Proceed to Accept</h4>
        </GridItem>
        <GridItem>
          <p style={{color: 'white',}}>
            Please press "Proceed" to accept the details of this transfer.
          </p>
          <p style={{color: 'white',}}>
            Note that, once you press proceed, a binding agreement is created.
          </p>
        </GridItem>
        </GridContainer>
        <GridContainer style={{marginTop: 48}}>
          <GridItem xs={12}>

            <Button
              style={{
                width: "100%",
                color: 'purple'
              }}
              color="white"
              onClick={()=>this.handleTransferCreation()}
              disabled={this.state.seconds < 5}
            >
              {this.state.seconds < 5 ? (
                <span>Fetching Rate</span>
              ) : (
                <span>
                  Proceed ({this.state.seconds} sec until new
                  rate)
                </span>
              )}
            </Button>
            {/* <Button onClick={()=>this.handleTransferCreation()}>Proceed</Button> */}
            <Button
              style={{
                width: "100%"
              }}
              color="purple"
              onClick={()=>this.handleConfirmationFail()}
              // disabled={this.state.seconds < 5}
            >
              <span>Cancel</span>
            </Button>
            {/* <Button onClick={()=>this.handleConfirmationFail()}>Cancel</Button> */}
          </GridItem>
        </GridContainer>

      </>
    )
  }



  handleTransferCreation = async () => {
    console.log('handleTransferCreation')
    this.setState({transferSubmitting: true})

    let testClient = false;
    if (this.props.app_state.current_client.id === 55) {
      testClient = true;
    }

    try {
    let set_date = this.getNextBusinessDay(new Date(), 2);
    let settlement_date = set_date
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");

    let adjusted_remittance_reference;

    if (this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail && this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_mandatory) {
      adjusted_remittance_reference = this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_mandatory
    } else if (this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail && this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_prefix) {
      adjusted_remittance_reference = this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.reference_prefix + this.props.app_state.current_client.id
    } else {
      adjusted_remittance_reference = "FS" + this.props.app_state.current_client.id.toString().padStart(5, "0");
    }

    try {
      await API.post("email", `/interaction/main`, {
        body: {
          interaction: "New Transfer Request",
          data: {
            ...this.props.transfers_dashboard.new_transfer_data,
            ...this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail,
            adjusted_remittance_reference,
            client_id: this.props.app_state.current_client.id,
            client_nickname: this.props.app_state.current_client.nickname,
            record_created_datetime: new Date()
              .toISOString()
              .slice(0, 19)
              .replace("T", " "),
            transaction_datetime: new Date()
              .toISOString()
              .slice(0, 10)
              .replace("T", " "),
            settlement_date: moment(set_date).format("DD/MM/YYYY"),
            status: "2",
            from: "DashboardTransfer",
          },
          result: "New Transfer Request in process"
        }
      });
      console.log('handleTransferCreation - email sent')
    } catch (error) {
      console.log('handleTransferCreation - email error', error)
    }


    let amountFromIncludingFee = this.props.transfers_dashboard.new_transfer_data.amount_from;
    if (this.props.transfers_dashboard.new_transfer_data.feeAmount) {
      amountFromIncludingFee = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_from) + Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.feeAmount);
    }

    let createTransferResult = await API.post(
      'payments',
      `/create/from_dashboard`,
      {
          body: {
            ...this.props.transfers_dashboard.new_transfer_data,
            clientId: this.props.app_state.current_client.id,
            nickname: `${
              // this.props.transfers_dashboard.new_transfer_data.amount_from
              amountFromIncludingFee
            } ${
              this.props.transfers_dashboard.new_transfer_data.currency_from_name
            } to ${this.props.transfers_dashboard.new_transfer_data.amount_to} ${
              this.props.transfers_dashboard.new_transfer_data.currency_to_name
            } Settling ${moment(set_date).format("DD/MM/YYYY")}`,
            record_created_datetime: new Date()
              .toISOString()
              .slice(0, 10)
              .replace("T", " "),
            transaction_datetime: new Date()
              .toISOString()
              .slice(0, 10)
              .replace("T", " "),
            settlement_date: settlement_date,
            client_id: this.props.app_state.current_client.id,
            staff_id: this.props.app_state.current_client.responsible_staff_member,
            beneficiary_ids: this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id
          },
      }
    )
    console.log('handleTransferCreation - createTransferResult', createTransferResult)
    const transferID = createTransferResult.payload.database_result.insertId

    const message = `
        A new transfer has been created.<br/>
        <strong>Client: </strong>${this.props.app_state.current_client.nickname}<br/>
        <strong>Client ID: </strong>${this.props.app_state.current_client.id}<br/>
        <strong>From Currency: </strong>${this.props.transfers_dashboard.new_transfer_data.currency_from.full_name}<br/>
        <strong>From Amount: </strong>${amountFromIncludingFee}<br/>
        ${this.props.transfers_dashboard.new_transfer_data.ledgerFrom && ( 
          `<strong>From Ledger: </strong>This transfer was requested to come from client ledger account ID: ${this.props.transfers_dashboard.new_transfer_data.ledgerFrom}<br/>`
        )}
        <strong>Fee: </strong>${this.props.transfers_dashboard.new_transfer_data.feeAmount} ${this.props.transfers_dashboard.new_transfer_data.feeCurrencyIso}<br/>
        <strong>Fee Desc: </strong>${this.props.transfers_dashboard.new_transfer_data.feeDescription}<br/>
        <strong>Rate: </strong>${this.props.transfers_dashboard.new_transfer_data.client_rate}<br/>
        <strong>To Currency: </strong>${this.props.transfers_dashboard.new_transfer_data.currency_to.full_name}<br/>
        <strong>To Amount: </strong>${this.props.transfers_dashboard.new_transfer_data.amount_to}<br/>
        ${this.props.transfers_dashboard.new_transfer_data.ledgerTo && (
          `<strong>To Ledger: </strong>This transfer was requested to go to client ledger account ID: ${this.props.transfers_dashboard.new_transfer_data.ledgerTo}<br/>`
        )}
        <strong>Settlement Date: </strong>${moment(set_date).format("DD/MM/YYYY")}<br/>
        <hr/>
        <strong>Transfer ID: </strong>${transferID}<br/>
        <strong>Backend: </strong><a href="http://forexsport.com/fww/fwwadmin/new_requests.php">http://forexsport.com/fww/fwwadmin/new_requests.php</a><br/>
        <strong>Admin: <strong><a href="https://crm.forexworldwide.com/transfers/edit/${transferID}">Transfer ID ${transferID}</a><br/>
      `
    // console.log(message)
    await API.post('email', '/interaction/backoffice', {
      body: {
        testClient: testClient,
        clientID: this.props.app_state.current_client.id,
        subject: `${testClient ? 'TEST TRANSFER - ' : ''} FWW Client Portal: New Transfer Request Created`,
        message: message,
      }
    });


      // Only send remittance instructions if there is a remitting bank
    if (this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail) {
      await API.post("email", `/client/remittance-instructions-new`, {
        body: {
          id: this.props.app_state.current_client.id,
          transferID,
          // rate: this.props.transfers_dashboard.new_transfer_data.client_rate,
          settlement_date: moment(set_date).format("DD/MM/YYYY"),
          // amount_to: this.props.transfers_dashboard.new_transfer_data.amount_to,
          // currency_to: this.props.transfers_dashboard.new_transfer_data.currency_to.full_name,
          // currency_to_iso3: this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3,
          // amount_from: amountFromIncludingFee,
          // amount_from: this.props.transfers_dashboard.new_transfer_data
          //   .amount_from,
          // currency_from: this.props.transfers_dashboard.new_transfer_data.currency_from.full_name,
          // currency_from_iso3: this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3,
          remitting_bank_id: this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.id,
          // eba_account_name: this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.eba_accout_name,
          remittance_reference: adjusted_remittance_reference,
          // language_id: this.props.app_state.language_id,
          // payment_method_reference: this.props.transfers_dashboard.new_transfer_data.payment_method_reference,
          // payment_method_url: this.props.transfers_dashboard.new_transfer_data.payment_method_url,
          ledgerFrom: this.props.transfers_dashboard.new_transfer_data.ledgerFrom,
          // ledgerTo: this.props.transfers_dashboard.new_transfer_data.ledgerTo,
          send_to_holding_acc_checkbox: this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox,
          payout_details: this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id,
          noRem: this.props.transfers_dashboard.new_transfer_data.ledgerFrom ? true : false
        }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
      console.log('handleTransferCreation - remittance instructions sent')
    } else {
      console.log('handleTransferCreation - remittance instructions not sent')
    }
    this.setState({transferSubmitting: false})
    this.props.clearNewTransfer()
    this.props.history.push(`/transfers/edit/${transferID}?tab=remittance`);
    
    return

    // // Step 3 - Create transfer
    // this.props.createNewTransfer({
    //   ...this.props.transfers_dashboard.new_transfer_data,
    //   clientId: this.props.app_state.current_client.id,
    //   nickname: `${
    //     // this.props.transfers_dashboard.new_transfer_data.amount_from
    //     amountFromIncludingFee
    //   } ${
    //     this.props.transfers_dashboard.new_transfer_data.currency_from_name
    //   } to ${this.props.transfers_dashboard.new_transfer_data.amount_to} ${
    //     this.props.transfers_dashboard.new_transfer_data.currency_to_name
    //   } Settling ${moment(set_date).format("DD/MM/YYYY")}`,
    //   record_created_datetime: new Date()
    //     .toISOString()
    //     .slice(0, 10)
    //     .replace("T", " "),
    //   transaction_datetime: new Date()
    //     .toISOString()
    //     .slice(0, 10)
    //     .replace("T", " "),
    //   settlement_date: settlement_date,
    //   client_id: this.props.app_state.current_client.id,
    //   staff_id: this.props.app_state.current_client.responsible_staff_member,
    //   beneficiary_ids: this.props.transfers_dashboard.new_transfer_data
    //     .multiple_beneficiary_id
    // });

    // console.log('handleTransferCreation - transfer created')

    // return





    // // Only send remittance instructions if there is a remitting bank
    // if (this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail) {
    //   API.post("email", `/client/remittance-instructions-new`, {
    //     body: {
    //       id: this.props.app_state.current_client.id,
    //       rate: this.props.transfers_dashboard.new_transfer_data.client_rate,
    //       settlement_date: moment(set_date).format("DD/MM/YYYY"),
    //       amount_to: this.props.transfers_dashboard.new_transfer_data.amount_to,
    //       currency_to: this.props.transfers_dashboard.new_transfer_data.currency_to.full_name,
    //       currency_to_iso3: this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3,
    //       amount_from: amountFromIncludingFee,
    //       // amount_from: this.props.transfers_dashboard.new_transfer_data
    //       //   .amount_from,
    //       currency_from: this.props.transfers_dashboard.new_transfer_data.currency_from.full_name,
    //       currency_from_iso3: this.props.transfers_dashboard.new_transfer_data.currency_from.iso_alpha_3,
    //       remitting_bank_id: this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.id,
    //       eba_account_name: this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail.eba_accout_name,
    //       remittance_reference: adjusted_remittance_reference,
    //       language_id: this.props.app_state.language_id,
    //       payment_method_reference: this.props.transfers_dashboard.new_transfer_data.payment_method_reference,
    //       payment_method_url: this.props.transfers_dashboard.new_transfer_data.payment_method_url,
    //       ledgerFrom: this.props.transfers_dashboard.new_transfer_data.ledgerFrom,
    //       ledgerTo: this.props.transfers_dashboard.new_transfer_data.ledgerTo,
    //       send_to_holding_acc_checkbox: this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox,
    //       payout_details: this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id
    //     }
    //   })
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    // }
    //   // return;
    // // // Step 2 - Email beneficiary
    // // if (
    // //   this.props.transfers_dashboard.new_transfer_data.checked_email_beneficiary
    // // ) {
    // //   // "Email beneficiary " checkbox is working
    // //   // the following API call is working but the content might need to be modified
    // //   API.post("email", `/interaction/main`, {
    // //     body: {
    // //       interaction: "New Transfer Request",
    // //       data: {
    // //         from: "Step 2 - Email beneficiary",
    // //         purpose: "test only"
    // //       },
    // //       result: "database submission in process"
    // //     }
    // //   });
    // // }

    
    
    // this.switch_to_next_change_current_step();



    // this.props.on_proceed_click();
    // this.setState({ current_step: 2});
  } catch (err) {
    console.log(err)
    console.log(err.message)

    API.post("email", `/interaction/main`, {
      body: {
        interaction: "New Transfer Request - ERROR",
        data: {
          ...this.props.transfers_dashboard.new_transfer_data,
          ...this.props.transfers_dashboard.new_transfer_data.remitting_bank_account_detail,
          client_id: this.props.app_state.current_client.id,
          client_nickname: this.props.app_state.current_client.nickname,
          record_created_datetime: new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          transaction_datetime: new Date()
            .toISOString()
            .slice(0, 10)
            .replace("T", " "),
          from: "DashboardTransfer",
        },
        result: err.toString()
      }
    });
    this.setState({transferSubmitting: false})
  }

  };




  render() {
    const { classes } = this.props;
    let branding = "";
    try {
      switch (this.props.app_state.current_team.brand_app_name) {
        case "Forex WorldWide Money Transfer Service":
          branding = "Forex WorldWide Money Transfer Service";
          break;
        default:
          branding = this.props.app_state.current_team.brand_app_name;
          break;
      }
    } catch (err) {
      console.log(err);
      branding = "Forex WorldWide Money Transfer Service";
    }

    if (this.state.transferSubmitting) {
      return (
        <GridContainer style={{height: 300}}>
          <GridItem xs={12}>
          <Alert
              message="Transfer Submission in Process"
              description={<><p>
                Please wait while your transfer submission is in process.<br/>
                Do not leave or refresh this page until the submission is complete.
              </p>
              <p>
                This should take less than 30 seconds.
              </p>
              <p>
                After this process has completed you will be directed to the remittance instructions page.
              </p>
              <p>
                If you have been waiting longer than 30 second please click here to check your transfer list: 
                <a href='https://portal.forexworldwide.com/transfers/current_list'>Transfer List</a>.
              </p>
              </>}
              type="info"
              showIcon
            />
          </GridItem>
        </GridContainer>
      )
    }

    let transfer_step;
    switch (this.state.current_step) {
      case 1:
        transfer_step = (
          <DashboardTransferStep1
            prefill={this.props.prefill}
            on_back_click={() => this.switch_to_previous_change_current_step()}
            on_proceed_click={() => this.switch_to_next_change_current_step()}
            toggleButton={bool =>
              this.setState({
                firstStepDisabled: bool
              })
            }
            firstStepDisabled={this.state.firstStepDisabled}
            setDrawer={callable => {
              this.setState({ drawerInfo: callable });
            }}
          />
        );
        break;
      case 2:
        transfer_step = (
          <DashboardTransferStep2
            prefill={this.props.prefill}
            on_back_click={() => this.switch_to_previous_change_current_step()}
            on_proceed_click={() => this.switch_to_next_change_current_step()}
            switch_to_step1={() => this.switch_to_step1()}
            startTimer={() => this.startTimer()}
            go={this.go}
            stopTimer={() => this.stopTimer()}
            setCallable={callable =>
              this.setState({ secondStepFunction: callable })
            }
            setRateState={callable => this.setState({ fetchNewRate: callable })}
          />
        );
        break;
      case 3:
        transfer_step = (
          <DashboardTransferStep3
            prefill={this.props.prefill}
            switch_to_step1={() => this.switch_to_step1()}
          />
        );
        break;
      default:
        transfer_step = <DashboardTransferStep1 />;
        break;
    }

    return (
      <React.Fragment>
        <GridItem xs={12}>
          <Card style={{ height: "90%" }}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  {transfer_step}
                </GridItem>
                <GridItem xs={12} md={6}>
                  {this.renderMapComponent()}
                  <CustomDrawer
                    content={this.getInstructions()}
                    toggleDraw={toggle =>
                      this.setState({ toggleDrawer: toggle })
                    }
                  />
                  <CustomDrawer
                    content={this.getConfirmationContent()}
                    toggleDraw={toggle =>
                      this.setState({ toggleConfirmation: toggle })
                    }
                  />
                </GridItem>

                <GridContainer>
                  {(() => {
                    switch (this.state.current_step) {
                      case 1:
                        return (
                          <React.Fragment>
                            <GridItem xs={3} />
                            <GridItem
                              xs={3}
                              className={classes.paddingZero}
                              style={{ textAlign: "right" }}
                            />
                            <GridItem xs={2} className={classes.paddingZero}>
                              <Button
                                style={{
                                  width: "80%"
                                }}
                                // color="purple"
                                disabled={this.state.firstStepDisabled}
                                onClick={() =>
                                  this.handleClearValues()
                                }
                              >
                                Clear Form
                              </Button>
                            </GridItem>
                            <GridItem xs={3} className={classes.paddingZero}>
                              <Button
                                style={{
                                  width: "80%"
                                }}
                                color="purple"
                                disabled={this.state.firstStepDisabled}
                                onClick={() =>
                                  this.handleProceedStep1()
                                }
                              >
                                Proceed
                              </Button>
                            </GridItem>
                          </React.Fragment>
                        );
                      case 2:
                        return (
                          <React.Fragment>
                            <GridItem xs={6} />
                            <GridItem
                              xs={3}
                              className={classes.paddingZero}
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                style={{
                                  width: "80%"
                                }}
                                color="white"
                                onClick={() =>
                                  this.switch_to_previous_change_current_step()
                                }
                              >
                                Modify Request
                              </Button>
                            </GridItem>

                            <GridItem xs={3} className={classes.paddingZero}>
                              <Button
                                style={{
                                  width: "80%"
                                }}
                                color="purple"
                                onClick={this.onClickSecondStep}
                                disabled={this.state.seconds < 5}
                              >
                                {this.state.seconds < 5 ? (
                                  <span>Fetching Rate</span>
                                ) : (
                                  <span>
                                    Proceed ({this.state.seconds} sec until new
                                    rate)
                                  </span>
                                )}
                              </Button>
                            </GridItem>
                          </React.Fragment>
                        );
                      case 3:
                        return (
                          <React.Fragment>
                            <GridItem xs={6} />
                            <GridItem
                              xs={3}
                              className={classes.paddingZero}
                              style={{ textAlign: "right" }}
                            />

                            <GridItem xs={3} className={classes.paddingZero}>
                              <Button
                                style={{
                                  width: "80%"
                                }}
                                color="purple"
                                onClick={this.handle_new_transfer}
                              >
                                New Transfer
                              </Button>
                            </GridItem>
                          </React.Fragment>
                        );
                      default:
                        transfer_step = <DashboardTransferStep1 />;
                        break;
                    }
                  })()}
                </GridContainer>
              </GridContainer>
              <br />
            </CardBody>
            {this.render_economic_information()}
          </Card>
        </GridItem>
        <GridItem />
        {/* <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.economic_info_modal_open}
          transition={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              {this.state.economic_info_modal_title}
            </h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            {this.get_economic_info_modal()}
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.handle_economic_info_modal_close()}
              color="success"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    transfers_dashboard: state.transfers_dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    change_current_step: step => {
      dispatch(change_current_step(step));
    },
    clearNewTransfer: () => {
      dispatch(clearNewTransfer());
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    },
    createNewTransfer: data => {
      dispatch(createNewTransfer(data));
    }

  };
};

const DashboardTransferContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(dashboardStyle)(DashboardTransfer)));

export default injectIntl(DashboardTransferContainer);
