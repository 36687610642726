import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedNumber } from 'react-intl'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import 'react-dates/initialize'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Badge from '../Badge/Badge'
import { fetchForwardList } from '../../redux/actions/forwards'
import {Table, Select, Button} from "antd";
import ListTable from "../ListTable/ListTable";
import dayjs from "dayjs";
import FormItem from "antd/es/form/FormItem";
import Dvr from "@material-ui/icons/Dvr";
const styles = {
    currency: {
        display: 'flex',
        alignItems: 'center',
        // marginBottom: 4,

        '& > span': {
            marginLeft: 4,
        },
    },
    badge: {
        cursor: 'pointer',
    },
}

const Forwards = ({ forwards, fetching, fetchForwardList, classes, app_state, status, getColumnSearchProps }) => {
    const [state, setState] = useState({
        startDate: '',
        endDate: '',
    })
    const [Kpairs, setKPairs] = useState([])
    const [filteredForward, setFilteredForward] = useState([])
    const [to, setTo] = useState(0)
    const [from, setFrom] = useState(0)
    const [average, setAverage] = useState(0)
    const history = useHistory()
    useEffect(() => {
        fetchForwardList(app_state.current_client.id)
    }, [])

    useEffect(() => {
        fetchForwardList(app_state.current_client.id)
    }, [app_state.current_client.id])
    useEffect(
        () => {
            const pairs = []
            if (forwards.length > 0) {
                forwards.map((forward, key) => {
                    if (key == 0) {
                        pairs.push({
                            one: forward.currency_from_iso_alpha_3,
                            two: forward.currency_to_iso_alpha_3,
                        })
                    } else {
                        const temp = pairs.find(
                            x =>
                                x.one == forward.currency_from_iso_alpha_3 &&
                                x.two == forward.currency_to_iso_alpha_3
                        )
                        if (temp == null) {
                            pairs.push({
                                one: forward.currency_from_iso_alpha_3,
                                two: forward.currency_to_iso_alpha_3,
                            })
                        }
                    }
                })
                const temp = pairs.map((pair, key) => ({
                    value: pair.one + '/' + pair.two,
                    label: pair.one + '/' + pair.two,
                }))
                setKPairs(temp)
                setState({
                    ...state,
                    pair: temp[0].value,
                })
                filterPairs(temp[0])
            }
        },
        [forwards]
    )
    const filterPairs = pair => {
        const pairSplit = pair.label.split('/')
        const temp = forwards.filter(
            forward =>
                forward.currency_from_iso_alpha_3 == pairSplit[0] &&
                forward.currency_to_iso_alpha_3 == pairSplit[1]
        )
        let fromT = 0
        let toT = 0
        let rateT = 0
        let count = 0
        temp.forEach(item => {
            fromT += item.amount_from
            toT += item.amount_to
            if (item.client_rate) {
                rateT += item.client_rate
                count++
            }
        })
        setTo(toT)
        setFrom(fromT)
        // setAverage(toT / fromT);
        if (count > 0) {
            setAverage(rateT / count)
        }

        temp.forEach(item => {
            item.settlement_date = item.settlement_date != null ? dayjs(item.settlement_date).format('DD/MM/YYYY') : '-'
            item.transaction_date = item.transaction_date != null ? dayjs(item.transaction_date).format('DD/MM/YYYY') : '-'
        })

        setFilteredForward(temp)
    }
    const handleDateChange = (startDate, endDate) => {
        setState(state => ({ ...state, startDate, endDate }))
    }

    const filterItems = () => {
        const data = [...filteredForward];
        if (state.startDate && state.endDate) {
            return data.filter(
                forward =>
                    state.startDate.isBefore(
                        moment(forward.settlement_date).add(1, 'day')
                    ) && state.endDate.isAfter(moment(forward.settlement_date) && status.includes(forward.status_id))
            )
        } else {
            return data.filter(item => status.includes(item.status_id));
        }
    }

    const handleCustomReactSelectChange = (name, value) => {
        let option_value
        let option_label
        if (value === null) {
            option_value = null
            option_label = null
        } else {
            option_value = value.value
            option_label = value.label
        }
        filterPairs(value)
        setState({
            ...state,
            [name]: option_value,
        })
    }

    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={2}>
                    <FormItem labelAlign={'left'} label={<strong>Currency Pair</strong>}>
                        <Select
                            placeholder={state.pair}
                            options={Kpairs}
                            onChange={(e) => handleCustomReactSelectChange('pair', {value: e, label: e})}
                        />
                    </FormItem>
                </GridItem>
                <GridItem xs={7} />
                {state.pair && (
                    <GridItem xs={3}>
                        <h5>Currency Pair Stats:</h5>
                        <p>
                            <strong>Total {state.pair.split('/')[0]}:</strong>{' '}
                            {from.toFixed(2)}
                        </p>
                        <p>
                            <strong>Total {state.pair.split('/')[1]}:</strong>{' '}
                            {to.toFixed(2)}
                        </p>
                        <p>
                            <strong>Average Rate: </strong>
                            {average.toFixed(4)}
                        </p>
                    </GridItem>
                )}
            </GridContainer>
            <h5>Settlement Date Select:</h5>
            <DateRangePicker
                style={{ marginBottom: '5px' }}
                isOutsideRange={() => false}
                startDate={state.startDate}
                startDateId="your_unique_start_date_id"
                endDate={state.endDate}
                endDateId="your_unique_end_date_id"
                displayFormat="DD/MM/YYYY"
                onDatesChange={({ startDate, endDate }) =>
                    handleDateChange(startDate, endDate)
                }
                focusedInput={state.focusedInput}
                onFocusChange={focusedInput =>
                    setState(state => ({ ...state, focusedInput }))
                }
            />
            <Table
                dataSource={filterItems()}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        sorter: (a, b) => a.id  - b.id,
                        ...getColumnSearchProps({
                            dataIndex: 'id',
                            label: 'ID',
                            render: (text, c) => (
                                <div
                                    className={classes.badge}
                                    onClick={() =>
                                        history.push(
                                            `/forwards/detail/${c.id}`
                                        )
                                    }
                                >
                                    <Badge
                                        color={
                                            c.status_nickname === 'Draft'
                                                ? 'warning'
                                                : c.status_nickname ===
                                                'Submitted'
                                                    ? 'info'
                                                    : c.status_nickname ===
                                                    'Acknowledged'
                                                        ? 'success'
                                                        : c
                                                            .status_nickname ===
                                                        'Processing'
                                                            ? 'success'
                                                            : c
                                                                .status_nickname ===
                                                            'Awaiting Funds'
                                                                ? 'danger'
                                                                : c
                                                                    .status_nickname ===
                                                                'Funds Received'
                                                                    ? 'danger'
                                                                    : c
                                                                        .status_nickname ===
                                                                    'Payment Made'
                                                                        ? 'success'
                                                                        : c
                                                                            .status_nickname ===
                                                                        'Completed'
                                                                            ? 'primary'
                                                                            : c
                                                                                .original
                                                                                .status_nickname ===
                                                                            'On Hold'
                                                                                ? 'danger'
                                                                                : c
                                                                                    .original
                                                                                    .status_nickname ===
                                                                                'Not Proceeding'
                                                                                    ? 'primary'
                                                                                    : 'success'
                                        }
                                    >
                                        ID: {c.id}
                                    </Badge>
                                </div>
                            )
                        })
                    },
                    {
                        title: 'Date',
                        dataIndex: 'transaction_date',
                        ...getColumnSearchProps({
                            label: 'Date',
                            dataIndex: 'transaction_date',
                            filterInputType: 'DATE',
                            render: (text, c) => (
                                <React.Fragment>
                                    <p>
                                        {c.transaction_date}
                                    </p>
                                </React.Fragment>
                            )
                        })
                    },
                    {
                        title: 'Settlement',
                        dataIndex: 'settlement_date',
                        ...getColumnSearchProps({
                            label: 'Settlement Date',
                            dataIndex: 'settlement_date',
                            filterInputType: 'DATE',
                            render: (text, record) => (
                                <React.Fragment>
                                    <p>{record.settlement_date}</p>
                                </React.Fragment>
                            )
                        })
                    },
                    {
                        title: 'From',
                        dataIndex: 'amount_from',
                        ...getColumnSearchProps({
                            label: 'From',
                            dataIndex: 'amount_from',
                            render: (text, c) => (
                                <>
                                    <div className={classes.currency}>
                                        <div
                                            className={`currency-flag currency-flag-${
                                                c.currency_from_iso_alpha_3
                                                    ? c.currency_from_iso_alpha_3.toLowerCase()
                                                    : ''
                                            }`}
                                        />
                                        <span>
                                        {text}
                                    </span>
                                    </div>
                                    <FormattedNumber
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        value={c.amount_from}
                                    />
                                </>
                            )
                        })
                    },
                    {
                        title: 'Rate',
                        dataIndex: 'client_rate',
                        ...getColumnSearchProps({
                            label: 'Rate',
                            dataIndex: 'client_rate',
                            render: (text, c) => (
                                <React.Fragment>
                                    <p>
                                        {isNaN(parseFloat(c.client_rate))
                                            ? 'Not Set'
                                            : parseFloat(
                                                c.client_rate
                                            ).toFixed(4)}
                                    </p>
                                </React.Fragment>
                            )
                        })
                    },
                    {
                        title: 'To',
                        dataIndex: 'amount_to',
                        ...getColumnSearchProps({
                            label: 'To',
                            dataIndex: 'amount_to',
                            render: (text, c) => (
                                <>
                                    <div className={classes.currency}>
                                        <div
                                            className={`currency-flag currency-flag-${
                                                c.currency_to_iso_alpha_3
                                                    ? c.currency_to_iso_alpha_3.toLowerCase()
                                                    : ''
                                            }`}
                                        />
                                        <span>
                                        {c.currency_to_iso_alpha_3}
                                    </span>
                                    </div>
                                    <FormattedNumber
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        value={c.amount_to}
                                    />
                                </>
                            )
                        })

                    },
                    {
                        title: 'Balance',
                        dataIndex: 'balance',
                        ...getColumnSearchProps({
                            label: 'Balance',
                            dataIndex: 'balance',
                            render: (text, c) => (
                                <>
                                    <div className={classes.currency}>
                                        <div
                                            className={`currency-flag currency-flag-${
                                                c.currency_to_iso_alpha_3
                                                    ? c.currency_to_iso_alpha_3.toLowerCase()
                                                    : ''
                                            }`}
                                        />
                                        <span>
                                        {c.currency_to_iso_alpha_3}
                                    </span>
                                    </div>
                                    <FormattedNumber
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        value={c.balance}
                                    />
                                </>
                            )
                        })
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status_nickname',
                        render: (text) => (
                            <React.Fragment>
                                <p>{text}</p>
                            </React.Fragment>
                        ),
                    },
                    {
                        title: 'Action',
                        dataIndex: 'deleted',
                        ...getColumnSearchProps({
                            dataIndex: 'deleted',
                            label: 'Actions',
                            render: (text, record) => {
                                return <>
                                        <Button type={'link'} onClick={() => history.push(`/forwards/detail/${record.id}`)}>
                                            <Dvr />
                                        </Button>
                                </>
                            }
                        })

                    },
                ]}
                className="-highlight"
            />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    app_state: state.app_state,
    forwards: state.forwards.forwardList,
    fetching: state.forwards.fetching,
})

const mapDispatchToProps = dispatch => ({
    fetchForwardList: (clientId) => dispatch(fetchForwardList(clientId)),
})

const ForwardsComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(Forwards))))

export default ForwardsComponent
