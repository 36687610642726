import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles'

import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle'
import LanguageSelectionContainer from '../CustomDropdown/LanguageSelectionContainer'
import { ClientDropdownList } from './ClientDropdownList'
class HeaderLinks extends React.Component {
    state = {}

    componentDidMount() {}

    render() {
        const { classes } = this.props

        const wrapper = classNames({
            [classes.wrapperRTL]: true,
        })

        return (
            <React.Fragment>
                <div style={{ textAlign: 'right' }}>
                    <strong>
                        Client Portal Version 2.8.13
                    </strong>
                    <div
                        className={wrapper}
                        id="customDrop"
                        style={{ display: 'flex' }}
                    >
                        <ClientDropdownList />
                        <LanguageSelectionContainer />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
    }
}

const HeaderLinksContainer = connect(mapStateToProps)(HeaderLinks)

export default withRouter(withStyles(headerLinksStyle)(HeaderLinksContainer))
