import React from 'react'
import Chip from '@material-ui/core/Chip'
import {useHistory} from 'react-router-dom'
import {Col, Row} from 'antd'
import withStyles from "@material-ui/core/styles/withStyles";

/**
 *
 * @param data = {currencies_short_name: 'US', balance: 0.00, url: 'url link to specific page'}
 * @param borderColor
 * @param noChip
 * @param showValue
 * @param showBorder
 * @param emitRecord
 * @constructor
 * CHIP TYPE = FLAG, BUTTON, OPTION
 */

const styles = {
    chipBtn: {
        '&:hover': {
            background: '#000'
        }
    }
}
const CurrencyChip = ({
                          state,
                          classes,
                          data = [],
                          borderColor = 'none',
                          chipType = 'CHIP',
                          showValue = true,
                          showBorder = true,
                          emitRecord = undefined,
                          align = 'start',
                          span = 3
                      }) => {
    const history = useHistory()
    console.log('data', data)

    if (chipType === 'FLAG') {
        return data.map((record, key) => {
            return (
                (record.balance > 0 || record.is_last_30_days) && record.currencies_short_name != null && (
                    <Chip
                        key={key}
                        style={{
                            marginLeft: '5px',
                            border: `2px solid ${borderColor}`,
                            backgroundColor: 'white',
                        }}
                        icon={
                            <React.Fragment>
                                <div
                                    style={{marginLeft: 6, marginRight: 6}}
                                    className={`currency-flag currency-flag-${record.currencies_short_name.toLowerCase()}`}
                                />
                                {' '}
                                {record.currencies_short_name}
                            </React.Fragment>
                        }
                        label={
                            <div>
                                {new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: record.currencies_short_name,
                                    currencyDisplay: 'narrowSymbol',
                                }).format(record.balance)}
                            </div>
                        }
                        onClick={() => {
                            history.push(record.url)
                        }}
                    />
                )
            )
        })
    } else if (chipType === 'OPTION') {
        return (
            <Row gutter={[16,16]}>
                {data.map(record => {
                    return (record.balance > 0 || record.is_last_30_days) && record.currencies_short_name != null && <Col xs={24} sm={24} md={8} l={6} xl={4} style={{
                        width: '100%',
                        borderRadius: '5px',
                        margin: '10px',
                        display: 'inline-block',
                        backgroundColor: 'rgb(74, 176, 193)'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', float: 'left'}}>
                            <a
                                onClick={() =>
                                    emitRecord &&
                                    emitRecord(record)
                                }
                                style={{
                                    textDecoration: 'none',
                                    backgroundColor:
                                        'rgb(74, 176, 193)',
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                    padding: '5px',
                                    marginBottom: '5px',
                                }}
                            >
                                <img
                                    src={`https://wise.com/web-art/assets/flags/${(record.currencies_short_name || '').toLowerCase()}.svg`}
                                    alt=""
                                    width="25"
                                    height="25"
                                />
                                <h4
                                    style={{
                                        margin: '0',
                                        padding: '0',
                                        paddingTop: '5px',
                                        color: '#fff',
                                    }}
                                >
                                    {record.currencies_short_name}
                                </h4>
                            </a>
                            {showValue && (
                                <a
                                    onClick={() =>
                                        emitRecord &&
                                        emitRecord(record)
                                    }
                                    style={{
                                        textDecoration: 'none',
                                        backgroundColor:
                                            'rgb(74, 176, 193)',
                                        display: 'flex',
                                        alignContent: 'left',
                                        gap: '5px',
                                        width: 'auto',
                                        padding: '5px',
                                        justifyContent: 'start',
                                    }}
                                >
                                    <h4
                                        style={{
                                            margin: '0',
                                            padding: '0',
                                            color: '#fff',
                                        }}
                                    >
                                        {
                                            (record.balance > 0 || record.is_last_30_days) ?
                                                <strong>
                                                    {record.balance.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </strong> : ''
                                        }
                                    </h4>
                                </a>
                            )}
                            {showValue && state && state.current_client.id === 55 && (
                                <a
                                    onClick={() =>
                                        emitRecord &&
                                        emitRecord(record)
                                    }
                                    style={{
                                        textDecoration: 'none',
                                        backgroundColor:
                                            'rgb(74, 176, 193)',
                                        display: 'flex',
                                        alignContent: 'left',
                                        gap: '5px',
                                        width: 'auto',
                                        padding: '5px',
                                        justifyContent: 'start',
                                    }}
                                >
                                    <h4
                                        style={{
                                            margin: '0',
                                            padding: '0',
                                            color: '#fff',
                                        }}
                                    >
                                        {
                                            ((record.totalAvailable || 0) > 0 || record.is_last_30_days) ?
                                                <strong>
                                                    {(record.totalAvailable || 0).toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </strong> : ''
                                        }
                                    </h4>
                                </a>
                            )}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            float: 'right',
                            padding: '5px',
                            fontSize: '12px'
                        }}>
                            <span className={classes.chipBtn}
                                  onClick={() => emitRecord && emitRecord(record, 'WITHDRAW')}
                                  style={{
                                      backgroundColor: '#fff',
                                      cursor: 'pointer',
                                      color: 'rgb(74, 176, 193)',
                                      margin: '2px',
                                      padding: '4px',
                                      textAlign: 'center'
                                  }}>SEND MONEY</span>
                            <span className={classes.chipBtn}
                                  onClick={() => emitRecord && emitRecord(record, 'DEPOSIT')}
                                  style={{
                                      backgroundColor: '#fff',
                                      cursor: 'pointer',
                                      color: 'rgb(74, 176, 193)',
                                      margin: '2px',
                                      padding: '4px',
                                      textAlign: 'center'
                                  }}>ADD FUNDS</span>
                            <span className={classes.chipBtn}
                                  onClick={() => emitRecord && emitRecord(record, 'LEDGER')}
                                  style={{
                                      backgroundColor: '#fff',
                                      cursor: 'pointer',
                                      color: 'rgb(74, 176, 193)',
                                      margin: '2px',
                                      padding: '4px',
                                      textAlign: 'center'
                                  }}>LEDGER</span>
                        </div>
                        <div style={{clear: "both"}}></div>
                    </Col>
                })}
            </Row>
        )
    } else {
        return <Row gutter={16} justify={align}>
            {data.map((record, key) => {
                return (
                    (record.balance > 0 || record.is_last_30_days) && record.currencies_short_name != null && (
                        <>
                            <Col span={span}>
                        <span
                            style={{
                                display: 'inline-block',
                                float: align === 'start' ? 'left': 'right',
                                border: showBorder
                                    ? `2px solid ${borderColor || '#000'}`
                                    : '',
                                padding: '3px',
                                width: 'auto',
                                borderRadius: '5px',
                                backgroundColor:
                                    'rgb(74, 176, 193)',
                            }}
                        >
                            <a
                                onClick={() =>
                                    emitRecord &&
                                    emitRecord(record)
                                }
                                style={{
                                    textDecoration: 'none',
                                    backgroundColor:
                                        'rgb(74, 176, 193)',
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                    width: 'auto',
                                    padding: '5px',
                                    marginBottom: '5px',
                                }}
                            >
                                <img
                                    src={`https://wise.com/web-art/assets/flags/${(record.currencies_short_name || '').toLowerCase()}.svg`}
                                    alt=""
                                    width="25"
                                    height="25"
                                />
                                <h4
                                    style={{
                                        margin: '0',
                                        padding: '0',
                                        paddingTop: '5px',
                                        color: '#fff',
                                    }}
                                >
                                    {record.currencies_short_name}
                                </h4>
                            </a>
                            {showValue && (
                                <a
                                    onClick={() =>
                                        emitRecord &&
                                        emitRecord(record)
                                    }
                                    style={{
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        backgroundColor:
                                            'rgb(74, 176, 193)',
                                        display: 'flex',
                                        alignContent: 'center',
                                        gap: '5px',
                                        width: 'auto',
                                        padding: '5px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <h4
                                        style={{
                                            margin: '0',
                                            padding: '0',
                                            color: '#fff',
                                        }}
                                    >
                                        <strong>
                                            {record.balance.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </strong>
                                    </h4>
                                </a>
                            )}
                        </span>
                            </Col>
                        </>
                    )
                )
            })}
        </Row>
    }
}

export default withStyles(styles)(CurrencyChip)
