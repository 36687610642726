import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { onLoginSuccess, updateCurrentBrand, updateSrc } from '../../redux/actions'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'
import { Grid as DefaultGrid } from '@material-ui/core'

// @material-ui/icons
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
import Check from '@material-ui/icons/Check'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTextField from '../../components/CustomInput/CustomTextField'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { API, Auth } from 'aws-amplify'
import queryString from 'query-string'

import { FormattedMessage } from 'react-intl'

import {
    container,
    cardTitle,
} from '../../assets/jss/material-dashboard-pro-react.jsx'

import customCheckboxRadioSwitch from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import SweetAlert from 'react-bootstrap-sweetalert'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

const registerPageStyle = {
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        textAlign: 'center',
    },
    container: {
        ...container,
        position: 'relative',
        zIndex: '3',
    },
    root: {
        width: '100%',
        backgroundColor: 'transparent',
        padding: '0',
    },
    cardHeader: {
        marginBottom: '45px !important',
        width: '50%',
        margin: 'auto !important',
        textAlign: 'center !important',
    },
    cardSignup: {
        borderRadius: '6px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginBottom: '100px',
        padding: '0px 0px',
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
    left: {
        textAlign: 'left',
    },
    form: {},
    socialTitle: {
        fontSize: '18px',
    },
    customButtonClass: {
        '&,&:focus,&:hover': {
            color: '#FFFFFF',
        },
        marginLeft: '5px',
        marginRight: '5px',
    },
    inputAdornment: {
        marginRight: '18px',
        position: 'relative',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    customFormControlClasses: {
        margin: '0 12px',
    },
    checkboxLabelControl: {
        margin: '0',
    },
    checkboxLabel: {
        marginLeft: '6px',
        color: 'rgba(0, 0, 0, 0.26)',
    },
    activeButton: {
        width: '100%',
        border: '1px solid #57acee',
        borderRadius: '0',
    },
    nonActiveButton: {
        color: 'black',
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: '1px solid gray',
        borderRight: 0,
        borderRadius: '0',
    },
}

function facebookWaitForInit() {
    return new Promise((res, rej) => {
        const hasFbLoaded = () => {
            if (window.FB) {
                res()
            } else {
                setTimeout(hasFbLoaded, 300)
            }
        }
        hasFbLoaded()
    })
}

class RegisterPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
            social: false,
            facebookIsLoading: true,
            activeStep: 0,
            registration_step: '1',

            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_state: '',
            confirm_password: '',
            confirm_code: '',
            newUser: null,
            referral_code: this.props.match.params.referral_code
                ? this.props.match.params.referral_code
                : '',
            referrer: this.props.match.params.referrer
                ? this.props.match.params.referrer
                : '',

            existing_email: false,
        }
        this.handleToggle = this.handleToggle.bind(this)
    }

    componentDidMount = async () => {
        if (this.props.match.params.referral_code) {
            this.props.updateCurrentBrand(this.props.match.params.referral_code)
        }

        const query_strings = queryString.parse(this.props.location.search)
        if (query_strings) {
            if (query_strings.email) {
                this.setState({
                    email: query_strings.email,
                })
            }
            if (query_strings.name) {
                this.setState({
                    first_name: query_strings.name.split(' ')[0],
                    last_name: query_strings.name.split(' ')[1],
                })
            }
            if (query_strings.ref) {
                this.setState({
                    referrer: query_strings.ref,
                })
                console.log(query_strings.ref)
            }
            if (query_strings.src) {
                this.setState({
                    src: query_strings.src,
                })
                this.props.updateSrc(query_strings.src)
                console.log(query_strings.src)
            }
            if (query_strings.source === 'google') {
                this.setState({
                    source_message: 'using your Google account',
                    social: true,
                    password_helptext: (
                        <span style={{ fontWeight: 500 }}>
                            Please still select a password for this system.{' '}
                            <br />
                            You will thereafter be able to log in with your
                            social media account or password.
                        </span>
                    ),
                })
            }
            if (query_strings.source === 'facebook') {
                this.setState({
                    source_message: 'using your FaceBook account',
                    social: true,
                    password_helptext: (
                        <span style={{ fontWeight: 500 }}>
                            Please still select a password for this system.{' '}
                            <br />
                            You will thereafter be able to log in with your
                            social media account or password.
                        </span>
                    ),
                })
            }
        }

        const ga =
            window.gapi && window.gapi.auth2
                ? window.gapi.auth2.getAuthInstance()
                : null
        if (!ga) this.createScript()
        await facebookWaitForInit()
        this.setState({ facebookIsLoading: false })
    }

    handleToggle(value) {
        const { checked } = this.state
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        this.setState({
            checked: newChecked,
        })
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })

        if (event.target.id === 'confirm_code') {
            this.setState({
                [event.target.id]: event.target.value.trim(),
            })
        }

        /*
     *  Solution for #705 Register page needs to check for existing clients 
     */

        if (event.target.id === 'email') {
            //function to verify duplicated email #705 improvement
            if (this.verifyEmail(event.target.value)) {
                this.isDuplicatedEmail(event.target.value)
            }
        }

        /*
     *  Solution for #731 New registration validation
     */

        if (event.target.id === 'confirm_password') {
            //function to verify if it is correct password #731 improvement
            if (
                this.validatePasswordConfirm(
                    event,
                    event.target.value,
                    'confirm_password'
                )
            ) {
                this.setState({
                    passwordConfirm_helptext: '',
                })
            } else {
                this.setState({
                    passwordConfirm_helptext:
                        'Please re-type your password above',
                })
            }
        }
    }

    // Google Login Code
    createScript() {
        // load the Google SDK
        const script = document.createElement('script')
        script.src = 'https://apis.google.com/js/platform.js'
        script.async = true
        script.onload = this.initGapi
        document.body.appendChild(script)
    }

    initGapi() {
        // init the Google SDK client
        const g = window.gapi
        g.load('auth2', function() {
            g.auth2.init({
                client_id:
                    '466361976938-6j1ti27carhuh59pilb69ghv3o293q0e.apps.googleusercontent.com',
                // authorized scopes
                scope: 'profile email openid',
            })
        })
    }

    signInGoogle = () => {
        const ga = window.gapi.auth2.getAuthInstance()
        ga.signIn().then(
            googleUser => {
                this.getAWSCredentials(googleUser)
            },
            error => {
                console.log(error)
            }
        )
    }

    getAWSCredentials = async googleUser => {
        const { id_token, expires_at } = googleUser.getAuthResponse()
        const profile = googleUser.getBasicProfile()
        let user = {
            email: profile.getEmail(),
            name: profile.getName(),
        }

        await Auth.federatedSignIn(
            'google',
            { token: id_token, expires_at },
            user
        )

        const user_profile = await this.getUserProfileByEmail(user.email)

        if (user_profile) {
            this.props.userHasAuthenticated(true)
            this.props.set_current_user(user_profile.aws)
            this.props.onLoginSuccess(user_profile.aws_cognito_id)
            this.props.history.push('/')
        } else {
            this.props.history.push(
                `/pages/register?source=google&email=${user.email}&name=${
                    user.name
                }`
            )
        }
    }

    async getUserProfile(aws_cognito_id) {
        return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    }

    async getUserProfileByEmail(email) {
        return API.get('portal', `/users/get-by-email/${email}`)
    }

    responseGoogle = response => {}

    handleSubmit = async event => {
        event.preventDefault()
        console.log(this.state.referral_code.toLowerCase())
        console.log(this.state.referrer.toLowerCase())

        this.setState({ isLoading: true })

        try {
            const newUser = await Auth.signUp({
                username: this.state.email.toLowerCase(),
                password: this.state.password,
            })

            this.setState({
                newUser,
                registration_step: '2',
            })
            this.handleNext()
            await this.create_new_user_in_database(
                newUser.userSub,
                newUser.user.username.toLowerCase(),
                this.state.first_name,
                this.state.last_name,
                this.state.referral_code.toLowerCase(),
                this.state.referrer.toLowerCase(),
                this.state.src
            )

            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Client Registration',
                    data: {
                        username: newUser.user.username,
                        password: this.state.password,
                        cognito: newUser.userSub,
                        'referrer:': this.state.referrer,
                    },
                    result: 'AWS Cognito user created - awaiting confirmation',
                },
            })
                .then(response => {})
                .catch(error => {
                    console.log(error)
                })
        } catch (e) {
            console.log(e)
            if (e.code === 'UsernameExistsException') {
                try {
                    const newUser = await Auth.resendSignUp(this.state.email)
                    this.setState({
                        newUser,
                        registration_step: '2',
                    })
                } catch (e) {
                    console.log(e)
                    if (e.code === 'InvalidParameterException') {
                        this.props.history.push(
                            `/pages/login/${
                                this.props.app_state.current_brand
                            }?add_to_team=true`
                        )
                    }
                }
            }
        }

        this.setState({ isLoading: false })
    }

    async create_new_user_in_database(
        aws_cognito_id,
        email,
        first_name,
        last_name,
        referral_code,
        referrer,
        src
    ) {
        API.post('usersNew', `/users/create`, {
            body: {
                email: email,
                aws_cognito_id: aws_cognito_id,
                first_name: first_name,
                last_name: last_name,
                record_created_datetime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace('T', ' '),
                record_modified_datetime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace('T', ' '),
                portal_account_created: 1,
                portal_email_confirmed: 0,
                language_id: 1,
                referral_code: referral_code,
                referrer: referrer,
                src: src
            },
        })
            .then(response => {
                // console.log(response)
                if (this.state.existing_email) {
                    API.post('email', `/client/new-client-created`, {
                        body: {
                            data: {
                                email: this.state.email.toLowerCase(),
                                first_name: this.state.first_name,
                                last_name: this.state.last_name,
                                password: this.state.password,
                                user_id:
                                    response.payload.existing_client_check[0]
                                        .id,
                            },
                        },
                    })
                        .then(response => {
                            console.log(response)
                        })
                        .catch(error => {
                            console.log(error)
                        })

                    API.post('email', `/staff/new-client-created`, {
                        body: {
                            data: {
                                email: this.state.email,
                                first_name: this.state.first_name,
                                last_name: this.state.last_name,
                                client_id:
                                    response.payload.existing_client_check[0]
                                        .id,
                            },
                        },
                    })
                        .then(response => {
                            console.log(response)
                        })
                        .catch(error => {
                            console.log(error)
                        })
                } else {
                    API.post('email', `/client/new-client-created`, {
                        body: {
                            data: {
                                email: this.state.email.toLowerCase(),
                                first_name: this.state.first_name,
                                last_name: this.state.last_name,
                                password: this.state.password,
                                user_id: response.payload.insertId,
                            },
                        },
                    })
                        .then(response => {})
                        .catch(error => {
                            console.log(error)
                        })

                    API.post('email', `/staff/new-client-created`, {
                        body: {
                            data: {
                                email: this.state.email,
                                first_name: this.state.first_name,
                                last_name: this.state.last_name,
                                client_id: response.payload.insertId,
                            },
                        },
                    })
                        .then(response => {
                            // console.log(response);
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }

                return response
            })
            .catch(error => {
                console.log(error)
            })
    }

    async confirmUser(user_id, data) {
        return API.put('portal', `/users/update/${user_id}`, {
            body: data,
        })
    }

    resendAuthCode = async event => {
        try {
            await Auth.resendSignUp(this.state.email)
            alert(
                'New Verification code has been sent.  Please check your email (including Spam/Junk folders).'
            )
        } catch (e) {
            alert(e.message)
        }
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            await Auth.confirmSignUp(
                this.state.email.toLowerCase(),
                this.state.confirm_code
            )
            const signed_in_user = await Auth.signIn(
                this.state.email.toLowerCase(),
                this.state.password
            )

            await this.confirmUser(signed_in_user.username, {
                portal_email_confirmed: 1,
            })
            this.props.history.push('/')
            window.location.reload()
        } catch (e) {
            this.setState({ isLoading: false })
        }
    }

    // function that returns true if value is valid password, false otherwise
    validatePassword(event, value, stateName) {
        var passwordRex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\S)[A-Za-z\d\S]{6,}$/
        // This pattern requires at least 6 characters and contain at least 1 number and 1 character

        this.setState({ [stateName]: event.target.value })
        if (passwordRex.test(event.target.value)) {
            this.setState({
                password_state: 'success',
                password_helptext: '',
            })
            return true
        }
        this.setState({
            password_state: 'error',
            password_helptext:
                'Must be at least 6 characters and contain at least 1 number',
        })
        return false
    }

    validatePasswordConfirm(event, value, stateName) {
        if (this.state.password) {
            let passwordConfirmFlag = ''
            if (this.state.password == event.target.value) {
                passwordConfirmFlag = true
            } else {
                passwordConfirmFlag = false
            }
            return passwordConfirmFlag
        }
    }

    validateForm() {
        return (
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirm_password &&
            this.state.checked[0] === 1
        )
    }

    validateConfirmationForm() {
        return this.state.confirm_code.length === 6
    }

    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }

    warningAlert() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: 'block', marginTop: '-100px' }}
                    title={<span style={{ color: 'black' }}>Sorry!</span>}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button +
                        ' ' +
                        this.props.classes.success
                    }
                >
                    <span style={{ color: 'black' }}>
                        Facebook Login is presently unavailable
                    </span>
                </SweetAlert>
            ),
        })
    }

    hideAlert() {
        this.setState({
            alert: null,
        })
    }

    // function that returns true if email is Duplicated, false otherwise
    isDuplicatedEmail(value) {
        let isValidFlag = true
        API.get('clients', `/email_check/${value}`)
            .then(response => {
                if (response === 2) {
                    this.setState({
                        email_state: 'error',
                        email_error_message:
                            'Email address already exists in system.  Please re-type.',
                    })
                    isValidFlag = true
                } else if (response === 1) {
                    this.setState({
                        email_error_message: '',
                        existing_email: true,
                    })
                    isValidFlag = false
                } else if (response <= 0) {
                    this.setState({
                        email_error_message: '',
                    })
                    isValidFlag = false
                }
            })
            .catch(error => {
                console.log(error)
                isValidFlag = true
            })
        return isValidFlag
    }

    renderForm() {
        const { classes } = this.props

        //setup for language components
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')

        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                break
            case 'de':
                languageObj = languageObj_de_json
                break
            case 'zh':
                languageObj = languageObj_zh_json
                break
            case 'fr':
                languageObj = languageObj_fr_json
                break
            case 'it':
                languageObj = languageObj_it_json
                break
            case 'pt':
                languageObj = languageObj_pt_json
                break
            default:
                languageObj = languageObj_en_json
                break
        }
        return (
            <React.Fragment>
                <br />
                <DefaultGrid container justify="center">
                    <DefaultGrid item xs={6} sm={6} md={6}>
                        <NavLink
                            to={
                                '/pages/login/' +
                                this.props.app_state.current_brand
                            }
                        >
                            <Button className={classes.nonActiveButton}>
                                <b>LOGIN</b>
                            </Button>
                        </NavLink>
                    </DefaultGrid>
                    <DefaultGrid item xs={6} sm={6} md={6}>
                        <Button
                            color="twitter"
                            className={classes.activeButton}
                        >
                            <b>REGISTER</b>
                        </Button>
                    </DefaultGrid>
                </DefaultGrid>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                    <CustomTextField
                        // labelText="First Name..."
                        id="first_name"
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                            style: { marginLeft: '0' },
                        }}
                        inputProps={{
                            value: this.state.first_name,
                            variant: 'outlined',
                            label: languageObj['register.firstname'],
                            style: { backgroundColor: 'white' },
                            onChange: event => {
                                this.handleChange(event)
                            },
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Face
                                        className={classes.inputAdornmentIcon}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomTextField
                        id="last_name"
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                            style: { marginLeft: '0' },
                        }}
                        inputProps={{
                            value: this.state.last_name,
                            variant: 'outlined',
                            label: languageObj['register.lastname'],
                            style: { backgroundColor: 'white' },
                            onChange: event => {
                                this.handleChange(event)
                            },
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Face
                                        className={classes.inputAdornmentIcon}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomTextField
                        id="email"
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                            style: { marginLeft: '0' },
                        }}
                        helpText={this.state.email_error_message}
                        inputProps={{
                            value: this.state.email,
                            variant: 'outlined',
                            label: languageObj['register.email'],
                            style: { backgroundColor: 'white' },
                            onChange: event => {
                                this.handleChange(event)
                            },
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Email
                                        className={classes.inputAdornmentIcon}
                                    />
                                </InputAdornment>
                            ),
                            type: 'email',
                        }}
                    />
                    <CustomTextField
                        success={this.state.password_state === 'success'}
                        error={this.state.password_state === 'error'}
                        id="password"
                        helpText={this.state.password_helptext}
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                            style: { marginLeft: '0' },
                        }}
                        inputProps={{
                            value: this.state.password,
                            variant: 'outlined',
                            label: languageObj['register.password'],
                            style: { backgroundColor: 'white' },
                            onChange: event =>
                                this.validatePassword(
                                    event,
                                    'password',
                                    'password'
                                ),
                            type: 'password',
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Icon
                                        className={classes.inputAdornmentIcon}
                                    >
                                        lock_outline
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomTextField
                        success={
                            this.state.confirm_password_state === 'success'
                        }
                        error={this.state.confirm_password_state === 'error'}
                        id="confirm_password"
                        helpText={this.state.passwordConfirm_helptext}
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                            style: { paddingTop: '5%', marginLeft: '0' },
                        }}
                        inputProps={{
                            value: this.state.confirm_password,
                            variant: 'outlined',
                            label: languageObj['register.confirmPassword'],
                            style: { backgroundColor: 'white', padding: '0px' },
                            onChange: event => {
                                this.handleChange(event)
                            },
                            type: 'password',
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Icon
                                        className={classes.inputAdornmentIcon}
                                    >
                                        lock_outline
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <br />
                    <br />
                    <FormControlLabel
                        classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                        }}
                        control={
                            <Checkbox
                                tabIndex={-1}
                                value={this.state.agree_terms}
                                onClick={() => this.handleToggle(1)}
                                checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                }
                                icon={
                                    <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        }
                        label={
                            <span style={{ color: 'black' }}>
                                <FormattedMessage
                                    id="register.agreement"
                                    defaultMessage={`I agree to the `}
                                />
                                {' '}
                                <a
                                    href="https://www.forexworldwide.com/legal"
                                    target="_blank"
                                    style={{ color: 'black' }}
                                >
                                    <b>
                                        <FormattedMessage
                                            id="login.termsAndConditon"
                                            defaultMessage={`Terms and Conditions`}
                                        />
                                    </b>
                                </a>
                                <br/>
                                contained on www.forexworldwide.com
                                {` `}
                                {(this.props.app_state.current_brand 
                                    && this.props.app_state.current_brand !== 'null') && 
                                <em><small>[ref {this.props.app_state.current_brand}]</small></em>
                                }
                            </span>
                        }
                    />
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                color="purple"
                                style={{ height: '50px', fontSize: '14px' }}
                                disabled={!this.validateForm()}
                                key={'googlelogin'}
                                type="submit"
                                size="sm"
                                block
                            >
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormattedMessage
                                            id="register.registerbutton"
                                            defaultMessage={`Register Now`}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <br />


{/* COMMENTED OUT TO TEMPORARILY HIDE SOCIAL MEDIA REGISTRATION */}

                    {/* <p className={classes.center}>
                        <b>OR</b>
                    </p>
                    <GridContainer justify="center">
                        {!this.state.social && (
                            <GridItem xs={12} sm={12} md={12}>
                                <Button
                                    color="google"
                                    style={{ height: '50px', fontSize: '14px' }}
                                    key={'googlelogin'}
                                    onClick={this.signInGoogle}
                                    size="sm"
                                    block
                                >
                                    <GridContainer>
                                        <GridItem xs={1} sm={1} md={1}>
                                            <i className={'fab fa-google'} />
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10}>
                                            <FormattedMessage
                                                id="register.registerWithGooglebutton"
                                                defaultMessage={`Register with Google`}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </Button>
                            </GridItem>
                        )}
                    </GridContainer> */}

{/* COMMENTED OUT TO TEMPORARILY HIDE SOCIAL MEDIA REGISTRATION */}


                </form>
            </React.Fragment>
        )
    }

    renderConfirmationForm() {
        const { classes } = this.props
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')

        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                break
            case 'de':
                languageObj = languageObj_de_json
                break
            case 'zh':
                languageObj = languageObj_zh_json
                break
            case 'fr':
                languageObj = languageObj_fr_json
                break
            case 'it':
                languageObj = languageObj_it_json
                break
            case 'pt':
                languageObj = languageObj_pt_json
                break
            default:
                languageObj = languageObj_en_json
                break
        }
        return (
            <form
                className={classes.form}
                onSubmit={this.handleConfirmationSubmit}
            >
                <p style={{ fontWeight: '500', paddingTop: '10%' }}>
                    <FormattedMessage
                        id="register.ConfirmationForm.text1"
                        defaultMessage={`To confirm the email address provided is correct, a `}
                    />
                    <strong>
                        <FormattedMessage
                            id="register.ConfirmationForm.text2"
                            defaultMessage={`verification code"`}
                        />
                    </strong>
                    <FormattedMessage
                        id="register.ConfirmationForm.text3"
                        defaultMessage={` has been sent to `}
                    />
                    {` `}
                    <b>{this.state.email}</b>
                    <FormattedMessage
                        id="register.ConfirmationForm.text4"
                        defaultMessage={`. Please enter the code below:`}
                    />
                </p>
                <CustomTextField
                    // labelText="First Name..."
                    id="confirm_code"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                        style: { marginLeft: 0, paddingTop: '1%' },
                    }}
                    inputProps={{
                        value: this.state.confirm_code,
                        variant: 'outlined',
                        label: 'Enter Verification Code',
                        style: { backgroundColor: 'white', padding: '0px' },
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                    }}
                />
                {this.state.confirm_code.length !== 6 && (
                    <p style={{ color: 'red', paddingLeft: '5%' }}>
                        The confirm code must be six digits
                    </p>
                )}
                <br />
                <div className={classes.center}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                color="purple"
                                style={{ height: '50px' }}
                                disabled={!this.validateConfirmationForm()}
                                type="submit"
                                size="sm"
                                block
                            >
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <p
                                            style={{
                                                fontSize: '14px',
                                                paddingTop: '3%',
                                                letterSpacing: '2px',
                                            }}
                                        >
                                            <FormattedMessage
                                                id="register.renderConfirmationForm.validateCodeButton"
                                                defaultMessage={`VERIFY`}
                                            />
                                        </p>
                                    </GridItem>
                                </GridContainer>
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <Button
                        color="tumblr"
                        style={{
                            paddingTop: '0px',
                            color: 'black',
                            letterSpacing: '1px',
                        }}
                        simple
                        size="lg"
                        block
                        onClick={() => this.resendAuthCode()}
                    >
                        <strong>RESEND CODE</strong>
                    </Button>
                </div>
            </form>
        )
    }

    handleNext = () => {
        let t = this.state.activeStep
        this.setState({
            activeStep: t + 1,
        })
    }

    render() {
        const { classes } = this.props
        // let custom_message = "";

        // switch (this.props.app_state.current_brand) {
        //   case "gostudy":
        //     custom_message = "with GoStudy...";
        //     break;
        //   case "gostudyadmin":
        //     custom_message = "with GoStudy Admin Team...";
        //     break;
        //   case "gostudyaustralia":
        //     custom_message = "with GoStudy Australia...";
        //     break;
        //   case "gostudyitaly":
        //     custom_message = "with GoStudy Italy...";
        //     break;
        //   case "gostudyfrance":
        //     custom_message = "with GoStudy France...";
        //     break;
        //   case "gostudyspain":
        //     custom_message = "with GoStudy Spain...";
        //     break;
        //   case "redhill":
        //     custom_message = "with RedHill Education...";
        //     break;
        //   case "abcu":
        //     custom_message = "with ABC University...";
        //     break;
        //   case "smithsonian":
        //     custom_message = "with Smithsonian College...";
        //     break;
        //   case "smithsonianadelaide":
        //     custom_message = "with Smithsonian College Adelaide...";
        //     break;
        //   case "smithsoniansydney":
        //     custom_message = "with Smithsonian College Sydney...";
        //     break;
        //   case "dorman":
        //     custom_message = "with Dorman Securities Forex Transfer Services";
        //     break;
        //   case "abctours":
        //     custom_message = "with ABC Tours FX Portal";
        //     break;
        // }

        //setup for language components
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')

        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                break
            case 'de':
                languageObj = languageObj_de_json
                break
            case 'zh':
                languageObj = languageObj_zh_json
                break
            case 'fr':
                languageObj = languageObj_fr_json
                break
            case 'it':
                languageObj = languageObj_it_json
                break
            case 'pt':
                languageObj = languageObj_pt_json
                break
            default:
                languageObj = languageObj_en_json
                break
        }

        const steps = getSteps()

        function getSteps() {
            return ['PERSONAL DETAILS', 'VERIFICATION', 'COMPLETE REGISTRATION']
        }

        let register_form_title = 'Client Portal Register'
        register_form_title = languageObj['register.formTitle']

        switch (this.props.app_state.current_brand) {
            case 'abctravel':
                register_form_title = 'ABC Travel Client Register'
                break
            case 'gostudy':
                register_form_title = 'Go Study Student Register'
                break
            case 'dorman':
                register_form_title = 'IDTA FX Portal Register'
                break
            case 'idta':
                register_form_title = 'IDTA FX Portal Register'
                break
            case 'fwwsc':
                register_form_title = <span><strong>Forex Worldwide</strong><br/>FX Portal Registration</span>
                break
            case 'fwwjd':
                register_form_title = <span><strong>Forex Worldwide</strong><br/>FX Portal Registration</span>
                break
            case 'fwwpd':
                register_form_title = <span><strong>Forex Worldwide</strong><br/>FX Portal Registration</span>
                break
            case 'fxsjd':
                register_form_title = <span><strong>Forex Sport</strong><br/>FX Portal Registration</span>
                break
            case 'forexworldwide':
                register_form_title = <span><strong>Forex Worldwide</strong><br/>FX Portal Registration</span>
                break
            case 'forexsport':
                register_form_title = <span><strong>Forex Sport</strong><br/>FX Portal Registration</span>
                break
            case 'forexsportninja':
                register_form_title = <span><strong>Forex Sport (Ninja)</strong><br/>FX Portal Registration</span>
                break
            case 'skiaspen':
                register_form_title = <span><strong>Ski Aspen</strong><br/>FX Portal Registration</span>
                break
        }

        // Branding
        let logo
        let form_text = ''
        switch (this.props.app_state.current_brand) {
            case 'dorman':
                logo = require('assets/img/brand/idta.png')
                form_text = 'IDTA (Dorman)'
                break
            case 'idta':
                logo = require('assets/img/brand/idta.png')
                form_text = 'IDTA (Dorman)'
                break
            case 'gostudy':
                logo = require('assets/img/GOSTUDY_HorizontalWhite.png')
                form_text = 'GoStudy'
                break
            case 'abctravel':
                logo = require('assets/img/brand/travel-logo.jpg')
                form_text = 'ABC Travel'
                break
            case 'crocmedia':
                logo = require('assets/img/brand/idta.png')
                form_text = 'IDTA (Dorman)'
                break
            case 'forexsport':
                logo = require('assets/img/brand/forexsport.png')
                form_text = 'Forex Sport'
                break
            case 'forexsportninja':
                logo = require('assets/img/brand/forexsport.png')
                form_text = 'Forex Sport (Ninja)'
                break
            case 'forexworldwide':
                logo = require('assets/img/brand/forex_worldwide_edited.png')
                form_text = 'Forex WorldWide'
                break
            case 'skiaspen':
                logo = require('assets/img/brand/skiaspen.png')
                form_text = 'Ski Aspen';
                break
        }

        return (
            <div className={classes.container}>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={8} md={6}>
                        <Card className={classes.cardSignup}>
                            <CardHeader
                                className={`${classes.cardHeader} ${
                                    classes.center
                                }`}
                                color="primary"
                            >
                                {logo && (
                                    <img src={logo} height={70} width={''} />
                                )}
                                <h4
                                    className={classes.cardTitle}
                                    style={{
                                        paddingTop: 12,
                                        fontWeight: '300',
                                        color: 'white',
                                    }}
                                >
                                    {register_form_title}
                                </h4>
                            </CardHeader>
                            <CardBody
                                style={{
                                    backgroundColor: '#e0e0e0',
                                    boxShadow: 'none',
                                }}
                            >
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Stepper
                                            className={classes.root}
                                            activeStep={this.state.activeStep}
                                        >
                                            {steps.map(label => (
                                                <Step key={label}>
                                                    <StepLabel>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {' '}
                                                            {label}
                                                        </span>
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        
                                        {this.state.newUser === null
                                            ? this.renderForm()
                                            : this.renderConfirmationForm()}
                                        
                                        {this.state.referrer && <><strong>referrer:</strong>{this.state.referrer}</>}

                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

RegisterPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSrc: src => {
            dispatch(updateSrc(src))
        },
        updateCurrentBrand: brand => {
            dispatch(updateCurrentBrand(brand))
        },
        onLoginSuccess: user_id => {
            dispatch(onLoginSuccess(user_id))
        },
    }
}

const RegisterPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(registerPageStyle)(RegisterPage))

export default RegisterPageContainer
