import React from 'react';
import {Card, Col, DatePicker, Divider, Form, Input, message, Row, Select, Typography} from "antd";
import withStyles from "@material-ui/core/styles/withStyles";
import {RegistrationContext} from "../../../libs/RegistrationProvider";
import TypographyCountry from "../../CurrencySelect/TypographyCountry";
import {CaretDownOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {connect} from "react-redux";
import {createNewRegistration, updateNewRegistrationCreation} from "../../../redux/actions/registration";

const styles = {
    expandedBackground: {
        backgroundColor: 'rgb(240, 242, 245)',
        padding: '15px',
        borderRadius: '5px'
    },
    marginBottom: 0
}

class Step2ClientContact extends React.Component {

    clientContactForm = React.createRef();
    static contextType = RegistrationContext;

    constructor(props) {
        super(props);
        this.state = {
            formValues: {}
        }
    }

    componentDidMount() {
        this.clientContactForm.current.setFieldsValue({
            ...this.props.app_state.current_client
        });
    }

    getFormFields = (key) => {
        return this.state.formValues
        && this.state.formValues[key]
            ? this.state.formValues[key] : null
    }

    isValidated = () => {
        return true;
    }

    async preValidate() {
        this.context.setRegistration(prev => ({...prev, authorizedPerson: this.clientContactForm.current.getFieldsValue()}));
        return true;
    }

    handleSubmit = (values) => {
        // console.log(values);
    }

    sendState() {
        return this.state;
    }


    render() {
        return (
            <>
                <div className={this.props.classes.expandedBackground}>
                    <Form initialValues={{account_type: "1", account_business_type: "1"}} ref={this.clientContactForm}
                          disabled={this.context.registration.reg_disabled}
                          onValuesChange={(changed, all) => {
                              this.setState(prev => ({...prev, formValues: all}));
                          }} onFinish={this.handleSubmit}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Card size={'small'} title={'Authorized Person'}>
                                    <Typography.Paragraph strong={true} italic={true}>Provide details here for {this.props.app_state.current_client && this.props.app_state.current_client.nickname || ''}</Typography.Paragraph>
                                    <Form.Item labelCol={{span: 24}} wrapperCol={{span: 16}}
                                               normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                               rules={[{required: true, message: 'Date of Birth is required'}]}
                                               getValueProps={(value) =>
                                                   value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                               }
                                               name="date_of_birth"
                                               label={'Date of Birth'}>
                                        <DatePicker format={'DD/MM/YYYY'} />
                                    </Form.Item>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <Form.Item labelCol={{span: 24}}
                                                       rules={[{required: true, message: 'Place of Birth is required'}]}
                                                       wrapperCol={{span: 24}}
                                                       name="place_of_birth_city" label={'Place of Birth'}>
                                                <Input placeholder={'Enter place of birth'} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <Form.Item labelCol={{span: 24}} wrapperCol={{span: 24}}
                                                       rules={[{required: true, message: 'Country of birth is required'}]}
                                                       name="place_of_birth_country" label={'Place of Birth Country'}>
                                                <Select showSearch={true} allowClear={true} placeholder={'-- SELECT --'} optionFilterProp={'alt'} optionLabelProp={'label'} options={this.context.state.countries.map(i => ({
                                                    ...i,
                                                    value: i.id,
                                                    id: i.id,
                                                    alt: [i.iso_alpha_3, i.full_name],
                                                    label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                                }))}></Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        <Form.Item labelCol={{span: 24}}
                                                   wrapperCol={{span: 24}}
                                                   rules={[{required: true, message: 'Nationality is required'}]}
                                                   name="nationality" label={'Nationality'}>
                                            <Select showSearch={true}
                                                    allowClear={true}
                                                    optionFilterProp={'alt'}
                                                    placeholder={'-- SELECT --'}
                                                    suffixIcon={<CaretDownOutlined />}
                                                    optionLabelProp={'label'} options={this.context.state.countries.map(i => ({
                                                ...i,
                                                value: i.id,
                                                id: i.id,
                                                alt: [i.iso_alpha_3, i.full_name],
                                                label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                            }))}></Select>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        <Form.Item labelCol={{span: 24}} wrapperCol={{span: 24}}
                                                   rules={[{required: true, message: 'Language is required'}]}
                                                   name="language_id" label={'Language'}>
                                          <Select showSearch={true}
                                                  optionFilterProp={'label'}
                                                  optionLabelProp={'label'} allowClear={true} defaultValue={1} placeholder={'-- SELECT --'} options={this.context.state.language_id_list.map(item => ({
                                              label: item.nickname,
                                              value: item.id,
                                              id: item.id
                                          }))}></Select>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  <Form.Item labelCol={{span: 24}} wrapperCol={{span: 16}}
                                             rules={[{required: true, message: 'Occupation is required'}]}
                                             name="occupation" label={'Occupation'}>
                                    <Input placeholder={'Enter occupation'} />
                                  </Form.Item>
                                  <Divider />
                                  <Form.Item name={'external_reference'} label={'External Reference'} 
                                    help="Provide any external reference or member number. Eg Student Number or Trading Account Number">
                                      <Input placeholder={'Enter Reference No.'} />
                                  </Form.Item>
                                    {/* <Typography.Text italic={true}>Provide your account reference number here</Typography.Text> */}
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Card title={'Contact Details'} size={'small'} style={{ marginBottom: '10px' }}>
                                    <Typography.Paragraph italic={true}>Provide contact and address details for {this.props.app_state.current_client && this.props.app_state.current_client.nickname || ''}</Typography.Paragraph>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                            <Form.Item labelCol={{ span: 24 }}
                                                       wrapperCol={{ span: 24 }}
                                                       name={'telephone_preferred'}
                                                       rules={[{required: false, message: ''}]}
                                                       label={'Telephone Preferred'}>
                                                <Select placeholder={'-- ENTER PHONE --'} options={[
                                                    {value: 1, label: 'Home'},
                                                    {value: 2, label: 'Work'},
                                                    {value: 3, label: 'Mobile'},
                                                ]}></Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'telephone_mobile'}
                                                       rules={[{required: false, message: 'Phone is required'}]}
                                                       label={'Phone Number'}>
                                                <Input placeholder={'Enter Phone'} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                <Card title={'Residential Address'} size={'small'}>
                                    <Form.Item name={'residential_street_line_1'}
                                               rules={[{required: false, message: 'Street address is required'}]}
                                               label={'Resident Street Line 1'}>
                                        <Input placeholder={'Enter Address Line 1'} />
                                    </Form.Item>
                                    <Form.Item name={'residential_street_line_2'} label={'Resident Street Line 2'}>
                                        <Input placeholder={'Enter Address Line 2'} />
                                    </Form.Item>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'residential_street_suburb'}
                                                       rules={[{required: false, message: 'Suburb is required'}]}
                                                       label={'Suburb / City'}>
                                                <Input placeholder={'Enter Suburb'} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                            <Form.Item labelCol={{ span: 24 }}  wrapperCol={{ span: 24 }} name={'residential_street_state'} label={'State'}>
                                                <Input placeholder={'-'} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={'residential_street_postcode'} label={'Post Code'}>
                                                <Input placeholder={'e.g 12345'}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item labelCol={{span: 24}} wrapperCol={{span: 24}}
                                               rules={[{required: true, message: 'Country is required'}]}
                                               name="residential_street_country" label={'Country'}>
                                        <Select showSearch={true}
                                                allowClear={true}
                                                placeholder={'-- SELECT --'}
                                                optionFilterProp={'alt'}
                                                suffixIcon={<CaretDownOutlined />} optionLabelProp={'label'} options={this.context.state.countries.map(i => ({
                                            ...i,
                                            value: i.id,
                                            id: i.id,
                                            alt: [i.iso_alpha_3, i.full_name],
                                            label: <TypographyCountry iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                        }))}></Select>
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        registration: state.registration,
        language: state.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createNewRegistration: data => {
            dispatch(createNewRegistration(data));
        },
        updateNewRegistrationCreation: (key, value) => {
            dispatch(updateNewRegistrationCreation(key, value));
        }
    };
};


const Step2ClientContactHolderContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Step2ClientContact));

export default Step2ClientContactHolderContainer;