import React from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'
import ReactTable from 'react-table'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import Button from 'components/CustomButtons/Button.jsx'
import {Button as ButtonAntD, Spin, Table} from 'antd';

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'
import { matchSorter } from 'match-sorter'
import ListTable from "../ListTable/ListTable";

var moment = require('moment')

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
}

/**
 * ----------------------------------------------------------------------------
 * CLASS - IdentificationList
 * ----------------------------------------------------------------------------
 */
class HoldingAccountLineItemList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            line_item_list: [],
            dialog_item_detail_open: false,
            link_transfer: null,
            expandedRecord: [],
            currency_list: []
        }
    }

    fetchCurrencyList = () => {
        API.get('currencies', `/currencies/get-list-priority`, {}).then((response) => this.setState({currency_list: response}));
    }

    fetchHoldingAccounts = () => {
        this.fetchCurrencyList();
        if (this.props.account) {
            this.setState({line_item_list: []})
            API.get('holding_accounts', `/get/line_items/${this.props.account}`)
                .then(response => {
                    this.setState({
                        line_item_list: response.line_item_list,
                        holding_account: response.holding_account[0],
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    componentDidUpdate(prevprops) {
        if (this.props.state.refresh != prevprops.state.refresh || (this.props.account !== prevprops.account)) {
            this.fetchHoldingAccounts();
        }
    }

    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        const {
            fetchIdentificationList,
            fetchIdentificationListAll,
        } = this.props
        // console.log(this.props);
        // eslint-disable-next-line react/prop-types
    this.fetchHoldingAccounts()
    }

    buildTableData() {
        const line_item_list = this.state.line_item_list.sort((a, b) => {
            return moment(a.transaction_datetime).unix() - moment(b.transaction_datetime).unix() || a.id - b.id;
          });

          let runningBalance = 0;
          let lineItemListWithBalance = line_item_list.map((prop, key) => {
            runningBalance = runningBalance - prop.debit + prop.credit;
            return {
              id: prop.id,
              transaction_datetime: prop.transaction_datetime,
              memo: prop.memo,
              link_transfer: prop.link_transfer,
              debit: prop.debit,
              credit: prop.credit,
              balance: runningBalance,
              link_payout: prop.link_payout
            }
          })
          lineItemListWithBalance = lineItemListWithBalance.reverse();
      
        if (lineItemListWithBalance.length > 0) {
            // let balance = 0
            return lineItemListWithBalance.map((prop, key) => {
                // balance = balance - prop.debit + prop.credit
                return {
                    id: prop.id,
                    transaction_datetime: prop.transaction_datetime != null ? moment(prop.transaction_datetime).format('DD/MM/YYYY') : '-',
                    memo: prop.memo,
                    debit: prop.debit,
                    credit: prop.credit,
                    link_transfer: prop.link_transfer,
                    balance: prop.balance,
                    link_payout: prop.link_payout
                }
            })
        }
    }

    dialog_item_detail_open = async e => {
        console.log(e.target.id)
        await API.get('holding_accounts', `/get/line_item/${e.target.id}`)
            .then(response => {
                // console.log(this.props.holding_account_id);
                console.log(response)
                this.setState({
                    line_item: response.line_item[0],
                    dialog_item_detail_open: true,
                    link_transfer: response.line_item[0].link_transfer ? (
                        <React.Fragment>
                            <a
                                href={`/transfers/edit/${
                                    response.line_item[0].link_transfer
                                }`}
                            >
                                Click here
                            </a>{' '}
                            to view transfer
                        </React.Fragment>
                    ) : null,
                    link_payout: response.line_item[0].link_payout ? (
                        <React.Fragment>
                            <a
                                href={`/transfers/receipt/transfer/${
                                    response.line_item[0].link_payout
                                }`}
                            >
                                Click here
                            </a>{' '}
                            to view payout receipt
                        </React.Fragment>
                    ) : null,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    dialog_item_detail_close = () => {
        this.setState({
            dialog_item_detail_open: false,
            line_item: {},
        })
    }

    onRowExpand = (event, record) => {
        if (event === true) {
            if (this.state.expandedRecord[`row_${record.id}`] != null) {
                if (this.state.expandedRecord[`row_${record.id}`].status === 'ERROR') {
                    this.fetchAccountHoldingLineItemDetail(record);
                }
            } else {
                this.fetchAccountHoldingLineItemDetail(record);
            }
        }
    }

    getCurrency = (currencyId) => {
        const currency = this.state.currency_list.find(item => item.id === currencyId);
        console.log(currency);
        return currency.iso_alpha_3;
    }

    fetchAccountHoldingLineItemDetail = (record) => {
        const identifier = `row_${record.id}`;
        this.setState({expandedRecord: {...this.state.expandedRecord, [identifier]: {status: 'LOADING'}}});
        API.get('transfers', `/get/id-v2/${record.link_transfer}`, {})
            .then(response => {
                const data = {...this.state.expandedRecord, [identifier]: {
                        status: 'LOADED',
                        data: response
                    }}
                this.setState({expandedRecord: data});
            })
            .catch(error => {
                console.log(error);
                this.setState({expandedRecord: {...this.state.expandedRecord, [identifier]: {status: 'ERROR'}}});
            });
    }

    buildNestedTable = (data, record) => {

        if (record.credit > 0 && record.link_transfer == null) {
            return <table style={{maxWidth: 500}}>
                <tr>
                    <th style={{textAlign: 'left'}}>Transaction Date</th>
                    <td>{record.transaction_datetime}</td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>Memo</th>
                    <td>{record.memo == null ? '-': record.memo}</td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>Receipt</th>
                    <td>
                        <ButtonAntD type={'primary'} onClick={() => window.open(`/holding-account/receipt/${record.id}`)} size={'small'}>Receipt</ButtonAntD>
                    </td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>Credit</th>
                    <td>{new Intl.NumberFormat(
                        'en-GB',
                        {
                            style: 'currency',
                            currency: this.state
                                .holding_account
                                .currencies_short_name,
                        }
                    ).format(record.credit)}</td>
                </tr>
            </table>
        } else if (record.debit > 0 && record.link_transfer != null && record.link_payout != null) {
            return <table style={{maxWidth: 500}}>
                <tr>
                    <th style={{textAlign: 'left'}}>Transaction Date</th>
                    <td>{record.transaction_datetime}</td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>Memo</th>
                    <td>{record.memo}</td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>Payout</th>
                    <td><ButtonAntD type={'link'} onClick={() => this.props.history.push(`/transfers/receipt/transfer/${record.link_transfer}`)}>Click
                        Here</ButtonAntD> to view receipt
                    </td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>Debit</th>
                    <td>
                        {new Intl.NumberFormat(
                            'en-GB',
                            {
                                style: 'currency',
                                currency: this.state
                                    .holding_account
                                    .currencies_short_name,
                            }
                        ).format(record.debit)}
                    </td>
                </tr>
            </table>
        } else {
            return <table style={{maxWidth: 500}}>
                <tr>
                    <th style={{textAlign: 'left'}}>Transfer ID</th>
                    <td>{data.fullList.id}</td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>From</th>
                    <td>{`${this.getCurrency(data.fullList.currency_from_id)} ${data.fullList.amount_from}`}</td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>To</th>
                    <td>{`${this.getCurrency(data.fullList.currency_to_id)} ${data.fullList.amount_to}`}</td>
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>Client Rate</th>
                    <td>{data.fullList.client_rate}</td>
                </tr>
            </table>
        }
    }

    render() {
        return (
            <React.Fragment>
                <Table
                    style={{textAlign: 'right'}}
                    rowKey={'id'}
                    expandable={{
                        onExpand: (event, record) => {
                            this.onRowExpand(event, record)
                        },
                        expandedRowRender: (record) => {
                            const row = this.state.expandedRecord[`row_${record.id}`];
                            return <>
                                {row != null ? (
                                    row.status === 'LOADED'
                                        ? <>{this.buildNestedTable(row.data, record)}</>
                                        : (
                                            row.status === "LOADING"
                                                ? <><Spin/></>
                                                : <ButtonAntD type={'link'} danger={true}
                                                              onClick={() => this.fetchAccountHoldingLineItemDetail(record)}><strong>Try
                                                    again...</strong></ButtonAntD>
                                        )
                                ) : <Spin/>}
                            </>
                        },
                        rowExpandable: (row) => true
                    }}
                    dataSource={this.buildTableData()}
                    columns={[
                        {
                            title: 'Date',
                            dataIndex: 'transaction_datetime',
                            ...this.props.getColumnSearchProps({
                                title: 'Date',
                                dataIndex: 'transaction_datetime',
                                filterInputType: 'DATE',
                                render: (text, record) => (
                                    <React.Fragment>
                                        <strong>
                                            {text}
                                        </strong>
                                    </React.Fragment>
                                ),
                            })
                        },
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            sorter: (a, b) => a.id - b.id,
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'id',
                                render: (text, record) => (
                                    <React.Fragment>
                                    <span id={text} onClick={this.dialog_item_detail_open}>
                                       {text}
                                    </span>
                                    </React.Fragment>
                                ),
                            })
                        },
                        {
                            title: 'memo',
                            dataIndex: 'memo',
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'memo',
                                render: (text, record) => {
                                    if (text) {
                                        return (
                                            <React.Fragment>
                                                {text}
                                            </React.Fragment>
                                        )
                                    }
                                },
                            })
                        },
                        {
                            title: 'Debit',
                            dataIndex: 'debit',
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'debit',
                                render: (text, record) => {
                                    if (text) {
                                        return (
                                            <React.Fragment>
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {new Intl.NumberFormat(
                                                        'en-GB',
                                                        {
                                                            style: 'currency',
                                                            currency: this.state
                                                                .holding_account
                                                                .currencies_short_name,
                                                        }
                                                    ).format(text)}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                },
                            })
                        },
                        {
                            title: 'Credit',
                            dataIndex: 'credit',
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'credit',
                                render: (text, record) => {
                                    if (text) {
                                        return (
                                            <React.Fragment>
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {new Intl.NumberFormat(
                                                        'en-GB',
                                                        {
                                                            style: 'currency',
                                                            currency: this.state
                                                                .holding_account
                                                                .currencies_short_name,
                                                        }
                                                    ).format(text)}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                },
                            })
                        },
                        {
                            title: 'Balance',
                            dataIndex: 'balance',
                            ...this.props.getColumnSearchProps({
                                dataIndex: 'balance',
                                render: (text, record) => (
                                    <React.Fragment>
                                        <strong>
                                        <span
                                            style={{
                                                color: text < 0 ? 'red' : '',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {new Intl.NumberFormat('en-GB', {
                                                style: 'currency',
                                                currency: this.state
                                                    .holding_account
                                                    .currencies_short_name,
                                            }).format(text)}
                                        </span>
                                        </strong>
                                    </React.Fragment>
                                ),
                            })
                        },
                    ]}
                    className="-highlight"
                />
                <Button onClick={()=> this.fetchHoldingAccounts()}>Refresh</Button>
                <Dialog
                    open={this.state.dialog_item_detail_open}
                    onClose={this.dialog_item_detail_close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {this.state.line_item &&
                        this.state.line_item.id && (
                            <React.Fragment>
                                <DialogTitle idid="alert-dialog-title">
                                    Transaction ID:{' '}
                                    {this.state.line_item.id
                                        .toString()
                                        .padStart(8, '0')}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <table style={{ minWidth: 500 }}>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Transaction Date
                                                </th>
                                                <td>
                                                    {moment(
                                                        this.state.line_item
                                                            .transaction_datetime
                                                    ).format('DD/MM/YYYY')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Memo
                                                </th>
                                                <td>
                                                    {this.state.line_item.memo}
                                                </td>
                                            </tr>
                                            {this.state.link_transfer && (
                                                <tr>
                                                    <th
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        Transfer
                                                    </th>
                                                    <td>
                                                        {
                                                            this.state
                                                                .link_transfer
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            {this.state.link_payout && (
                                                <tr>
                                                    <th
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        Payout
                                                    </th>
                                                    <td>
                                                        {this.state.link_payout}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Debit
                                                </th>
                                                <td>
                                                    {new Intl.NumberFormat(
                                                        'en-GB',
                                                        {
                                                            style: 'currency',
                                                            currency: this.state
                                                                .holding_account
                                                                .currencies_short_name,
                                                        }
                                                    ).format(
                                                        this.state.line_item
                                                            .debit
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Credit
                                                </th>
                                                <td>
                                                    {new Intl.NumberFormat(
                                                        'en-GB',
                                                        {
                                                            style: 'currency',
                                                            currency: this.state
                                                                .holding_account
                                                                .currencies_short_name,
                                                        }
                                                    ).format(
                                                        this.state.line_item
                                                            .credit
                                                    )}
                                                </td>
                                            </tr>
                                        </table>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.dialog_item_detail_close}
                                        color="primary"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </React.Fragment>
                        )}
                </Dialog>
            </React.Fragment>
        )
    }
}

// export default withRouter(withStyles(styles)(IdentificationList));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // fetchIdentificationList: (client_id) => {
        //   dispatch(fetchIdentificationList(client_id))
        // },
        // fetchIdentificationListAll: () => {
        //   dispatch(fetchIdentificationListAll())
        // }
        // setBeneficiarySelectId: (id) => {
        //   dispatch(setBeneficiarySelectId(id))
        // }
    }
}

const HoldingAccountLineItemListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HoldingAccountLineItemList)

export default withRouter(
    withStyles(styles)(ListTable(HoldingAccountLineItemListContainer))
)
