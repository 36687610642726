import React from 'react'
import {connect} from "react-redux";
import {NavLink, withRouter} from 'react-router-dom';

import {API, Storage} from "aws-amplify";
import {Alert, Button} from 'antd';

import moment from 'moment';
import defaultPdf from "../../assets/img/receipts/downloadInvoicePdf.png";
import withStyles from "@material-ui/core/styles/withStyles";
import {isEmpty} from "../../libs/miscFunc";


const styles = {
    payNowButton: {
        marginRight: '20px',
        backgroundColor: 'rgb(74, 176, 193)',
        borderColor: 'rgb(74, 176, 193)',
        color: '#fff',
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: 'rgb(74, 176, 193)',
            color: '#fff'
        }
    },

}
class TransferAlerts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            transferList: [],
            pendingTransfers: [],
            allPendingTransferData: []
        }
    }

    componentDidMount() {
        this.fetchTransferList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_client !== this.props.app_state.current_client) {
          this.fetchTransferList()
        }
      }
    
    fetchTransferList = async () => {
        if (this.props.app_state && this.props.app_state.current_client) {
            const clientID = this.props.app_state.current_client.id
            try {
                await API.get('transfers', `/info-required/${clientID}`)
                .then(response => {
                    this.setState({transferList: response});
                })
                .catch(error => {
                    console.log(error)
                })
                await API.get('transfers_pending', `/list/client/${clientID}`)
                .then(response => {
                    this.setState({pendingTransfers: response});
                    API.post('transfers_pending', `/fetch/pending-transfers`, { body: {ids: response.map(item => item.id)}}).then(res => {
                        this.setState({ allPendingTransferData: res });
                    });
                })
                .catch(error => {
                    console.log(error)
                });
            } catch (error) {
                console.log(error)
            }
        }
    }

    renderTransferAlerts = () => {
        if (this.state.transferList.length === 0) { return null; }

        const alerts = this.state.transferList.map((transfer, index) => {
            const description = <span>Please provide the following information for the transfer ID {transfer.id}&nbsp;
            of {transfer.currency_from_iso_alpha_3} {Number.parseFloat(transfer.amount_from).toFixed(2)} to {transfer.currency_to_iso_alpha_3} {Number.parseFloat(transfer.amount_to).toFixed(2)}.<br/>
            You need to provide us with the following information: <strong>{transfer.infoRequiredNotes}</strong>.<br/>
            This can be uploaded by clicking <strong>
                <NavLink to={`/transfers/edit/${transfer.id}`}>HERE</NavLink>
            </strong> to upload online or emailed to our office at <a href='mailto:admin@forexsport.com'>admin@forexsport.com</a>.</span>
            return (
                <Alert
                    key={index}
                    message="Information Required for Transfer"
                    description={description}
                    type="warning"
                    showIcon
                    closable
                    />
            )
        })
        return alerts
    }

    filterTransferDataFromList = (transferId) => {
        const data = this.state.allPendingTransferData;
        return data.find(item => item.transfer_pending.id === transferId);
    }
    makeShortCutTransferUrl = async (transferId) => {
        const datum = this.filterTransferDataFromList(transferId);
        const amount_to = datum.transfer_pending.amount_to;
        const tpp_beneficiary_id = datum.payouts[0].beneficiary_id;
        const purpose_of_payment_detail = datum.payouts[0].purpose_of_payment_detail;
        const detail_1 = datum.payouts[0].detail_1
        const currency = await API.get('currencies', `/currencies/get/${datum.transfer_pending.currency_to_id}`, {});
        const currency_iso_alpha_3 = currency.iso_alpha_3;
        await API.put('transfers_pending', `/update/${transferId}`, { body: { status_id: 3}});
        this.props.history.push(`/shortcut_transfer/AUD-${currency_iso_alpha_3}--${amount_to}-${tpp_beneficiary_id}-${purpose_of_payment_detail}--${detail_1}?pt=${transferId}`)
    }

    downloadInvoice = (data) => {
        if (isEmpty(data.transfer_pending.attachmentFileId)) {
            this.props.history.push(`/payment-requests/view/${data.transfer_pending.id}`);
        } else {
            Storage.get(data.transfer_pending.attachmentFileId, {expires: 60}).then(item => {
                fetch(item)
                    .then(response => response.blob())
                    .then(blob => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = data.transfer_pending.id + '_REQUEST_INVOICE';
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => console.error('Error downloading file:', error));
            })
        }
    }

    renderInvoice = (transferId) => {
        const datum = this.filterTransferDataFromList(transferId);
        return <>
            <span style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
            }} onClick={() => this.downloadInvoice(datum)}>
                                                     <a>
                                                        <img width={50} src={defaultPdf} alt="PDF Icon"
                                                             className="pdf-image"/>
                                                     </a>
                                                     <a>View Invoice</a>
                                                </span>
        </>
    }

    prepareDescription = (transfer) => {
        return <>
        <div style={{display: "flex", justifyContent: 'space-between'}}>
                <span>You have a Pending Request (ID {transfer.id})&nbsp;
                    for <strong>{transfer.currency_iso_alpha_3} {Number.parseFloat(transfer.amount_to).toFixed(2)}</strong>.<br/>
                This is for a payment request to <strong>{transfer.beneficiary_nickname}</strong> for <strong>{transfer.detail_1}</strong> which
                should be paid by {moment(transfer.expiryDate).format('DD/MM/YYYY')}
                <hr/>
                <Button onClick={() => this.makeShortCutTransferUrl(transfer.id)} disabled={this.props.app_state.current_client.account_status !== 2} size={"large"} className={this.props.classes.payNowButton}>Pay Invoice Now</Button>
            </span>
                {this.renderInvoice(transfer.id)}
            </div>
        </>
    }

    renderPendingTransferAlerts = () => {
        if (this.state.pendingTransfers.length === 0) {
            return null;
        }

        const alerts = this.state.pendingTransfers.map((transfer, index) => {
            if (transfer.status_id == 1 || transfer.status_id == 4) return null;

            let expiryDate = moment();
            expiryDate = moment(transfer.expiryDate).format('DD/MM/YYYY')

            const title = <span><strong>{transfer.beneficiary_nickname}</strong> Payment Request</span>

            const description = this.prepareDescription(transfer);
            return (
                <Alert
                    key={index}
                    message={title}
                    description={description}
                    type="warning"
                    showIcon
                    closable
                    />
            )
        })
        return alerts
    }

    render() {

        
        return (
            <div>
                {this.renderTransferAlerts()}
                {this.renderPendingTransferAlerts()}
            </div>
        )
    }
}

// export default (TransferAlerts)


const mapStateToProps = state => {
    return {
      app_state: state.app_state,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
    };
  };
  
  TransferAlerts = connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(TransferAlerts));
  
  export default withStyles(styles)(TransferAlerts);
  