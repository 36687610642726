import React, {useState} from 'react';
import Table from '../../../../components/Table/Table'
import { injectIntl } from 'react-intl'
import {Button, Card, Col, DatePicker, Flex, Form, Input, message, Modal, Row, Tag} from 'antd'
import { connect } from 'react-redux'
import Clearfix from "../../../../components/Clearfix/Clearfix";
import {useForm} from "antd/es/form/Form";
import dayjs from "dayjs";
import {fetchForwardList} from "../../../../redux/actions/forwards";

const ForwardDetailSummary = ({forward, intl, forwards, fetchForwardDetails, fetchForwardList }) => {

    const [updateForm] = useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getDate = dateString => {
        dateString = new Date(dateString).toUTCString();
        dateString = dateString.split(' ').slice(0, 4).join(' ');
        return dateString;
    };

    const getTotalDrawDownsAmount = () => {
        let totalDrawDown = 0;
        const drawDownState = (forwards.forwardDrawDown || []);
        if (drawDownState != null && drawDownState.length > 0) {
            drawDownState.forEach(item => {
                if (item.amount_to != null ) {
                    totalDrawDown =  item.amount_to - totalDrawDown;
                }
            })
        }
        return totalDrawDown;
    }

    const handleAmount = (buttonId) => {

        let amount_from = updateForm.getFieldValue('amount_from');
        let amount_to = updateForm.getFieldValue('amount_to');
        let adjustedRate = updateForm.getFieldValue('client_rate');

        if (adjustedRate == null && (amount_from == null || amount_to == null)) {
            message.info('Enter the values first...');
            return;
        }


        switch (buttonId) {
            case 1:
                amount_from = Number.parseFloat(amount_from);
                amount_to = amount_from * adjustedRate;
                updateForm.setFieldValue('amount_to', amount_to.toFixed(2))
                break;

            case 2:
                amount_from = Number.parseFloat(amount_from);
                amount_to = amount_from / adjustedRate;
                updateForm.setFieldValue('amount_to', amount_to.toFixed(2))
                break;

            case 3:
                amount_to = Number.parseFloat(amount_to);
                amount_from = amount_to * adjustedRate;
                updateForm.setFieldValue('amount_from', amount_from.toFixed(2))
                break;

            case 4:
                amount_to = Number.parseFloat(amount_to);
                amount_from = amount_to / adjustedRate;
                updateForm.setFieldValue('amount_from', amount_from.toFixed(2))
                break;

        }
    }

    return <>
    <Row gutter={16}>
      <Col span={12}>
      <Card>
          <div className="ant-card-head-title" style={{float: 'left'}}>Original Forward</div>
          <Clearfix />
        <Table
            tableData={[
                [
                    'Transaction Date/Time',
                    <div>
                        <span>{`${forward.transaction_date ? getDate(forward.transaction_date) : ''}`}</span>
                    </div>
                ],
                [
                    'Amount From',
                    <>
                        {forward.amount_from ? (
                            <>
                                <div
                                    className={`currency-flag currency-flag-${forward.currency_from_short_name.toLowerCase()}`}
                                />{' '}
                                {forward.currency_from_short_name}{' '}
                                {intl.formatNumber(forward.amount_from, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ],
                [
                    'Amount To',
                    <>
                        {forward.amount_to ? (
                            <>
                                <div className={`currency-flag currency-flag-${forward.currency_to_short_name.toLowerCase()}`} />{' '}
                                {forward.currency_to_short_name.toUpperCase()}{' '}
                                {intl.formatNumber(forward.amount_to, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ],
                [
                    'Rate',
                    <>
                        {forward.client_rate ? (
                            <>
                                {intl.formatNumber(forward.client_rate, {
                                    minimumFractionDigits: 5,
                                    maximumFractionDigits: 5
                                })}
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ],
                [
                    'Settlement Date',
                    <>
                        {forward.settlement_date ? (
                            <>
                                <div>
                                    <span>{`${getDate(forward.settlement_date)}`}</span>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ],
                [
                    'Status',
                    <>
                        {forward.status ? (
                            <>
                                <div>
                                    <span>{`${forward.status}`}</span>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ]
            ]}
        />
        </Card>
              </Col>
      <Col span={12}>
      <Card
        title="Balance Details"
        style={{height: '100%'}}
        >

          <Table
              tableData={[
                  [
                      'Original Amount',
                      <>
                          {forward.amount_to ? (
                              <>
                                  <div className={`currency-flag currency-flag-${forward.currency_to_short_name.toLowerCase()}`} />{' '}
                                  {forward.currency_to_short_name.toUpperCase()}{' '}
                                  {intl.formatNumber(forward.amount_to, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                  })}
                              </>
                          ) : (
                              ''
                          )}
                      </>
                  ],
                  [
                      'Total DrawDowns',
                      <>
                      {forward.amount_to ? (
                        <>
                        <img src={`https://wise.com/public-resources/assets/flags/rectangle/${forward.currency_to_short_name.toLowerCase()}.png`} width="24px" height="16px"/>{` `}
                        {forward.currency_to_short_name.toUpperCase()}{' '}
                        {intl.formatNumber(getTotalDrawDownsAmount(), {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </>
                        ): ''}
                      </>
                  ],
                  [
                      'Remaining Amount',
                      <>
                      {forward.amount_to ? (
                        <>
                        <strong>
                        <img src={`https://wise.com/public-resources/assets/flags/rectangle/${forward.currency_to_short_name.toLowerCase()}.png`} width="24px" height="16px"/>{` `}
                        {forward.currency_to_short_name.toUpperCase()}{' '}
                          {intl.formatNumber(forward.balance, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                          })}
                        </strong>
                        </>
                        ): ''}
                      </>
                  ]
              ]}
          />

        </Card>
      </Col>
    </Row>
</>
}

const mapStateToProps = state => {
   return state;
};

const mapDispatchToProps = dispatch => {
    return {fetchForwardList: () => dispatch(fetchForwardList())};
};
export default (connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForwardDetailSummary)));