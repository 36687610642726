import ListTable from "../../../components/ListTable/ListTable";
import {Button, Card, DatePicker, Form, InputNumber, message, Modal, Select, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useForm} from "antd/es/form/Form";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";
import {FormattedNumber} from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import ChartistGraph from "react-chartist";

const style = theme => ({
    chartistTooltip: {
        position: 'absolute',
        display: 'none',
        padding: '5px',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '3px',
        pointerEvents: 'none',
        zIndex: '100',
    },
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem',
            width: 52,
            height: 26,
        },
    },
    currencyLabel: {
        textAlign: 'left',
        '& > p': {
            marginBottom: -5,
            fontSize: '0.6rem',
        },
    }
})
const CurrencyElement = ({ classes, currency, value }) => (
    <div className={classes.currency}>
        <div
            className={`currency-flag currency-flag-${
                currency ? currency.toLowerCase() : ''
            }`}
        />
        <div className={classes.currencyLabel}>
            <p>{currency}</p>
            <FormattedNumber
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                value={value}
            />
        </div>
    </div>
)
const CashFlowPlanner = ({app_state, classes, getColumnSearchProps}) => {

    const [cashForm] = useForm();

    const [pieChart, setPieChart] = useState({
        payout: {
            labels: [],
            series: []
        },
        receipt: {
            labels: [],
            series: []
        }
    });


    const [barChart, setBarChart] = useState({
        labels: [],
        series: []
    })

    const [componentState, setComponentState] = useState({
        payoutList: [],
        allPayoutList: [],
        currencies: [],
        activePayoutId: null,
        isModalOpen: false,
        isLoading: false,
        isPlanReceipt: false,
        chartData: {
            highestDate: null,
            chartCurrency: 1,
            data: {
                labels: [],
                series: []
            },
            options: {
                height: '400px',
                showPoint: true,
                axisX: {
                    labelInterpolationFnc: function(value, i, j) {
                        const d = dayjs(j[j.length - 1], 'DD/MM/YYYY').startOf('day');
                        if ((d.isBefore(dayjs().add(6, 'month')) && i % 7 === 0)
                            || d.isBefore(dayjs().add(1, 'year')) && i % 16 === 0
                            || d.isBefore(dayjs().add(2, 'year')) && i % 32 === 0
                            || d.isBefore(dayjs().add(3, 'year')) && i % 64 === 0) {
                            return value;
                        }
                        return null;
                    },
                },
                axisY: {
                    onlyInteger: true,
                    scaleMinSpace: 20,
                },
                seriesBarDistance: 1
            }

        }
    });

    const fetchPayouts = () => {
        const loading = message.loading("Fetching payouts. Please wait..", 0);
        setComponentState(prev => ({...prev, isLoading: true }));
        let requestBody = {
            context: 'cashplan_records',
            fields: ['*'],
            condition: {deleted: null}
        };
        if (app_state.current_client && app_state.current_client.id) {
            requestBody = {...requestBody, condition: {...requestBody.condition, clientID: app_state.current_client.id}}
        }
        API.post("commons", "/fetch", {
            body: requestBody
        }).then(response => {

            const {labels, series} = prepareChartData(response, componentState.chartData.chartCurrency);
            const data = response.filter(i => i.currencyID === componentState.chartData.chartCurrency);
            setComponentState(prev => ({
                ...prev,
                payoutList: data,
                allPayoutList: response,
                isLoading: false,
                chartData: {
                    ...prev.chartData,
                    chartCurrency: componentState.chartData.chartCurrency,
                    data: {
                        labels: labels,
                        series: series,
                    },
                    options: {...prev.chartData.options, divisor: labels.length}
                }
            }));
            constructPieChart(response, 'payout');
            constructPieChart(response, 'receipt');
            constructBarChart(response);
        }).catch(err => {
            console.log(err);
            message.error("Unable to fetch the payout record. Please refresh the list to try again..");
        }).finally(() => loading());
    }

    const constructPieChart = (response, type) => {
        const today = dayjs();

        // Filter the data for dates later than today
        const filteredData = response.filter(item => dayjs(item.scheduled).isAfter(today) && item[type] != null);

        // Group the data by currencyID and sum the payouts
        const groupedData = filteredData.reduce((acc, item) => {
            if (item.currencyID && item[type]) {
                if (!acc[item.currencyID]) {
                    acc[item.currencyID] = 0;
                }
                acc[item.currencyID] += item[type];
            }
            return acc;
        }, {});


        // Prepare data for the chart
        const currencyObj = app_state.currency_list.reduce((acc, {id, iso_alpha_3}) => ({...acc, [id]: iso_alpha_3}), {});
        const labels = Object.keys(groupedData).map(currencyID => `${currencyObj[currencyID]}`);
        const series = Object.values(groupedData);

        setPieChart(prev => ({...prev, [type]: {...prev[type], labels: labels, series: series}}));
    }

    const constructBarChart = (response) => {
        const labels = response.reduce((acc, current) => {
            const d = acc.find(i => i.currencyID === current.currencyID);
            if (!d) acc.push(current);
            else Object.assign(d, current);
            return acc;
        }, []).reduce((acc, {currencyID}) => {
            const iso_alpha_3 = app_state.currency_list.find(item => item.id === currencyID).iso_alpha_3;
            return [...acc, iso_alpha_3];
        }, []);
        const currenciesObj = app_state.currency_list.reduce((acc, current) => ({...acc, [current.iso_alpha_3]: current}), {});
        const payoutSeries = labels.map(iso => {
             return response
                .filter(item => currenciesObj[iso].id === item.currencyID && item.payout != null)
                .reduce((acc, {payout}) => acc + payout, 0);
        });
        const receiptSeries = labels.map(iso => {
            return response
                .filter(item => currenciesObj[iso].id === item.currencyID && item.receipt != null)
                .reduce((acc, {receipt}) => acc + receipt, 0);
        });
        setBarChart(prev => ({...prev, labels: labels, series: [payoutSeries, receiptSeries]}));
    }

    const prepareChartData = (response, chartCurrency) => {

        if (response.length) {
            const currencies = response.map(item => item.currencyID);
            if (!currencies.includes(chartCurrency)) {
                message.info("There are no records with this chart currency. Please choose another currency", 3);
                return false;
            }
            const highestDate = response
                .filter(i => i.currencyID === chartCurrency)
                .map(i => i.scheduled)
                .reduce((latest, current) => dayjs(current).isAfter(dayjs(latest)) ? current : latest);

            let labels = [];
            let currentDate = dayjs();

            while (currentDate.startOf('day').isBefore(highestDate) || currentDate.startOf('day').isSame(highestDate)) {
                labels.push(currentDate.startOf('day').toISOString());
                currentDate = currentDate.add(1, 'day');
            }

            const payouts = [];
            const receipts = [];
            labels = labels.sort((a, b) => new Date(a) - new Date(b)).map(i => dayjs(i).format('DD/MM/YYYY'));
            labels.forEach(item => {
                const d = response.find(i => dayjs(i.scheduled).startOf('day').format('DD/MM/YYYY') === item && i.currencyID === chartCurrency && i.payout !== null);
                if (d != null) {
                    payouts.push(d.payout);
                } else {
                    payouts.push(0);
                }
            });

            labels.forEach(item => {
                const d = response.find(i => dayjs(i.scheduled).startOf('day').format('DD/MM/YYYY') === item && i.currencyID === chartCurrency && i.receipt !== null);
                if (d != null) {
                    receipts.push(d.receipt);
                } else {
                    receipts.push(0);
                }
            });

            return {labels, series: [payouts, receipts]}
        } else {
            return {labels: [], series: []};
        }
    }

    const fetchCurrencies = async () => {
        const response = await API.post("commons", "/fetch", {
            body: {
                context: 'currencies',
                fields: ['*'],
                condition: {deleted: [0, null]}
            }
        });
        setComponentState(prev => ({...prev, currencies: response}));
    }

    const onEditBtnClick = (payoutId) => {
        const loading = message.loading(`Loading id #${payoutId}. Please wait..`, 0);
        API.post("commons", "/fetch", {
            body: {
                context: 'cashplan_records',
                condition: {id: payoutId},
                fields: ['*']
            }
        }).then(res => {
            const [d] = res;
            setComponentState(prev => ({...prev, isModalOpen: true, activePayoutId: payoutId, isPlanReceipt: d.receipt != null && d.payout == null}));
            cashForm.setFieldsValue({...d, scheduled: dayjs(d.scheduled)});
        }).catch(err => {
            console.log(err);
            message.error("Failed to load. Please try again..");
        }).finally(() => loading());
    }

    const handleSubmit = (payload) => {
        const loading = message.loading("Saving payout record. Please wait..", 0);
        if (!componentState.activePayoutId) {
            payload.recordCreated = dayjs(new Date()).startOf("day");
        }
        payload.clientID = app_state.current_client.id;

        const request = componentState.activePayoutId != null ? API.post("commons", "/update", {
            body: {
                context: 'cashplan_records',
                data: payload,
                condition: {id: componentState.activePayoutId}
            }
        }) : API.post("commons", "/insert", {
            body: {
                context: 'cashplan_records',
                data: payload
            }
        });
        request.then(() => {
            message.success("Payout record saved");
            setComponentState(prev => ({...prev, activePayoutId: null, isModalOpen: false, isPlanReceipt: false }));
            fetchPayouts();
        }).catch(err => {
            console.log(err);
            message.error("Unable to save payout record. Please try again..");
        }).finally(() => loading());
    }

    const buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'ID',
                dataIndex: 'id',
                sorter: (a,b) => a.id - b.id,
                defaultSortOrder: 'descend'
            },
            {
                key: 'scheduled',
                title: 'Scheduled On',
                dataIndex: 'scheduled',
                ...getColumnSearchProps({
                    dataIndex: 'scheduled',
                    filterInputType: 'DATE',
                    render: (text, record) => dayjs(text).format('DD/MM/YYYY')
                })
            },
            {
                key: 'payout',
                title: 'Payout',
                dataIndex: 'payout',
                render: (text, record) => {
                    const currency = app_state.currency_list.find(i => i.id === record.currencyID);
                    if (currency) {
                        return <CurrencyElement
                            currency={currency.iso_alpha_3}
                            value={text}
                            classes={classes}
                        />
                    }
                }
            },
            {
                key: 'receipt',
                title: 'Receipt',
                dataIndex: 'receipt',
                render: (text, record) => {
                    const currency = app_state.currency_list.find(i => i.id === record.currencyID);
                    if (currency) {
                        return <CurrencyElement
                            currency={currency.iso_alpha_3}
                            value={text}
                            classes={classes}
                        />
                    }
                }
            },
            {
                key: 'recordCreated',
                title: 'Created',
                dataIndex: 'recordCreated',
                ...getColumnSearchProps({
                    dataIndex: 'recordCreated',
                    filterInputType: 'DATE',
                    render: (text, record) => text != null ? dayjs(text).format('DD/MM/YYYY'): '-'
                })
            },
            {
                key: 'action',
                title: '',
                dataIndex: 'clientID',
                render: (text, record) => <div style={{ display: 'flex', justifyContent: 'start', gap: 5 }}>
                    <Button type={'primary'} icon={<EditOutlined />} onClick={() => onEditBtnClick(record.id)}></Button>
                    <Button type={'primary'} icon={<DeleteOutlined />} danger={true} onClick={() => {
                        Modal.confirm({
                            title: `Delete ?`,
                            content: `Are you sure, you want to delete this payout record #${record.id} ?`,
                            onOk: () => {
                                const loading = message.loading("Removing. Please wait..", 0);
                                API.post("commons", "/update", {
                                    body: {
                                        context: 'cashplan_payout',
                                        data: {deleted: true},
                                        condition: {id: record.id}
                                    }
                                }).then(() => {
                                    fetchPayouts();
                                    message.success("Removed");
                                }).catch(err => {
                                    console.log(err);
                                    message.success("Failed");
                                }).finally(() => loading())
                            }
                        })
                    }}></Button>
                </div>
            }
        ]
    }

    useEffect(() => {
        fetchPayouts();
    }, [app_state.current_client]);

    useEffect(() => {
        const chart = document.querySelector('.ct-chart');

        const tooltip = document.createElement('div');
        tooltip.className = `${classes.chartistTooltip}`;
        document.body.appendChild(tooltip);

        chart.addEventListener('mouseover', (event) => {
            if (event.target.classList.contains('ct-bar')) {
                const value = event.target.getAttribute('ct:value');
                const  meta = event.target.getAttribute('meta');
                tooltip.innerHTML = `Payout: ${value}<br />Date: ${meta}`;
                tooltip.style.display = 'block';
                tooltip.style.left = `${event.pageX}px`;
                tooltip.style.top = `${event.pageY - 40}px`;
            }
        });

        chart.addEventListener('mouseout', () => {
            tooltip.style.display = 'none';
        });

        chart.addEventListener('mousemove', (event) => {
            tooltip.style.left = `${event.pageX}px`;
            tooltip.style.top = `${event.pageY - 40}px`;
        });
    }, [componentState.chartData.chartCurrency]);

    const listener = {
        draw: (data) => {
            if (data.type === 'bar') {
                const xAxisLabel = data.axisX.ticks[data.index];
                data.element.attr({
                    'meta': xAxisLabel,
                });
            }
        }
    }

    return <>
        <Card size={'small'} style={{ marginBottom: '10px', gap: '20' }}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <h3 style={{textAlign: 'center'}}>Payout</h3>
                    <ChartistGraph data={pieChart.payout} options={{
                        height: 250,
                        labelInterpolationFnc: function (value) {
                            return value;
                        },
                    }} type={'Pie'}/>
                </div>
                <div>
                    <h3 style={{textAlign: 'center'}}>Receipt</h3>
                    <ChartistGraph data={pieChart.receipt} options={{
                        height: 250,
                        labelInterpolationFnc: function (value) {
                            return value;
                        },
                    }} type={'Pie'}/>
                </div>
                <div>
                    <h3 style={{textAlign: 'center'}}>Bar Chart</h3>
                    <ChartistGraph
                        style={{margin: 20, fontWeight: 600}}
                        data={barChart}
                        type="Bar"
                        listener={listener}
                        options={{
                            height: 250,
                            showPoint: true,
                            axisX: {
                                labelInterpolationFnc: function(value, i, j) {
                                   return value;
                                },
                            },
                            axisY: {
                                onlyInteger: true,
                                scaleMinSpace: 20,
                            },
                            seriesBarDistance: 1
                        }}
                    />
                </div>
            </div>
        </Card>
        <Card style={{marginBottom: '10px'}} title={
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span>Cash Flow Planner</span>
                <div style={{display: "flex", gap: '5px'}}>
                    <Button type={'primary'} size={'small'} icon={<PlusOutlined/>}
                            onClick={() => setComponentState(prev => ({...prev, isModalOpen: true, isPlanReceipt: true }))}>Add Receipt
                        Record</Button>
                    <Button type={'primary'} size={'small'} icon={<PlusOutlined/>}
                            onClick={() => setComponentState(prev => ({...prev, isModalOpen: true}))}>Add New
                        Payout</Button>
                    <Button type={'primary'} danger={true} size={'small'} onClick={() => fetchPayouts()}
                            icon={<ReloadOutlined/>}>Refresh List</Button>
                </div>
            </div>
        }>
            <div style={{display: 'flex', justifyContent: 'start', gap: '10px', marginBottom: '10px'}}>
                <Form.Item label={'Currency'} name={'selected_currency'}>
                    <Select allowClear={false}
                            showSearch={true}
                            style={{width: '250px'}}
                            optionFilterProp={'alt'}
                            options={app_state.currency_list.map(i => ({
                                ...i,
                                label: <TypographyCurrency
                                    iso_alpha_3={i.iso_alpha_3}>[{i.iso_alpha_3}] {i.full_name}</TypographyCurrency>,
                                value: i.id,
                                alt: `${i.full_name} ${i.iso_alpha_3}`
                            }))}
                            onChange={((val, datum) => {
                                const {labels, series} = prepareChartData(componentState.allPayoutList, datum['id']);
                                const data = val != null ? componentState.allPayoutList.filter(i => i.currencyID === val) : componentState.allPayoutList;
                                setComponentState(prev => ({
                                    ...prev,
                                    payoutList: data,
                                    chartData: {
                                        ...prev.chartData,
                                        chartCurrency: datum['id'],
                                        data: {
                                            series: series,
                                            labels: labels
                                        }
                                    }
                                }));
                            })}
                            placeholder={'-- SELECT --'}></Select>
                </Form.Item>
            </div>
            <Table columns={buildColumns()}
                   dataSource={componentState.payoutList}
                   loading={componentState.isLoading}></Table>
            <Modal visible={componentState.isModalOpen}
                   title={componentState.activePayoutId ? `Update Payout #${componentState.activePayoutId} Record` : 'Add New Record'}
                   okText={
                       componentState.activePayoutId
                           ? `Update ${componentState.isPlanReceipt ? 'Receipt' : 'Payout'} Record`
                           : `Add ${componentState.isPlanReceipt ? 'Receipt' : 'Payout'} Record`
                   }
                   onCancel={() => {
                       cashForm.resetFields();
                       setComponentState(prev => ({...prev, isModalOpen: false, activePayoutId: null, isPlanReceipt: false}));
                   }}
                   onOk={() => cashForm.submit()}>
                <Form labelCol={{span: 8}} name={'payout-form'} form={cashForm} onFinish={handleSubmit}>
                    <Form.Item name={'currencyID'} label={'Currency to'}>
                        <Select optionFilterProp={'alt'}
                                showSearch={true}
                                style={{minWidth: '100px'}}
                                options={app_state.currency_list.map(i => ({
                                    ...i,
                                    value: i.id,
                                    alt: i.iso_alpha_3,
                                    label: <TypographyCurrency
                                        iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                                }))}></Select>
                    </Form.Item>
                    {
                        !componentState.isPlanReceipt && <Form.Item name={'payout'} label={'Payout'}>
                            <InputNumber/>
                        </Form.Item>
                    }
                    {
                        componentState.isPlanReceipt && <Form.Item name={'receipt'} label={'Receipt'}>
                            <InputNumber/>
                        </Form.Item>
                    }
                    <Form.Item label={'Schedule On'} name={'scheduled'} normalize={(dayjsValue) =>
                        dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                    }
                               getValueProps={(value) =>
                                   value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                               }>
                        <DatePicker format={'DD/MM/YYYY'}/>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
        <Card title={'Currency Plan Chart'}>
            <>
                <ChartistGraph
                    key={componentState.chartData.chartCurrency}
                    style={{margin: 20, fontWeight: 600}}
                    data={componentState.chartData.data}
                    type="Bar"
                    listener={listener}
                    options={componentState.chartData.options}
                />
            </>
        </Card>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ListTable(CashFlowPlanner)));