import React from 'react';
// import forexWorldWideLogo from '../assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png';
import forexSportLogo from '../assets/img/pdf_img/fxsport_signature_gmail.png';

import forexWorldwideLetterheadLogo from '../assets/img/brand/team_001/forexworldwide_500x100.png'
import forexSportLetterheadLogo from '../assets/img/brand/team_029/forexsport_500x100_2.png'

// Footers
import forexWorldwideLetterheadFooter from '../assets/img/brand/team_001/forex_worldwide_footer.png'
import forexSportLetterheadFooter from '../assets/img/brand/team_029/forex_sport_footer.png'


export function getLetterheadLogo(teamID) {
    console.log(teamID)
    switch (teamID) {
        case 1:
            // Forex Worldwide
            return forexWorldwideLetterheadLogo
            break
        case 24:
            // Ninja Trader IDTA
            return forexSportLetterheadLogo
            break
        case 29:
            // Forex Sport
            return forexSportLogo
            break
        case 48:
            // Forex Sport [JD]
            return forexSportLogo
            break
        case 49:
            // Forex Sport Ninja
            return forexSportLetterheadLogo
            break
        default:
            return forexWorldwideLetterheadLogo
    }
}

export function receiptFooter(teamID) {
    switch (teamID) {
        case 1:
            // Forex Worldwide
            return (
              <React.Fragment>
                  <p style={{fontSize: 14, lineHeight: 1.1}}>
                      <br />
                      <br />
                      <img
                        src={forexWorldwideLetterheadFooter}
                        alt='Forex Worldwide Logo'
                        style={{width: '100%'}}
                      />
                  </p>
              </React.Fragment>
            )
            break;
        case 24:
            // Ninja Trader IDTA
            return (
              <React.Fragment>
                  <p style={{fontSize: 14, lineHeight: 1.1}}>
                      <br />
                      <br />
                      <img
                        src={forexSportLetterheadFooter}
                        alt='ForexSport Logo'
                        style={{width: '100%'}}
                      />
                  </p>
              </React.Fragment>
            )
            break;
        case 29:
            // Forex Sport
            return (
              <React.Fragment>
                  <p style={{fontSize: 14, lineHeight: 1.1}}>
                      <br />
                      <br />
                      <img
                        src={forexSportLetterheadFooter}
                        alt='ForexSport Logo'
                        style={{width: '100%'}}
                      />
                  </p>
              </React.Fragment>
            )
            break
        case 48:
            // Forex Sport [JD]
            return (
              <React.Fragment>
                  <p style={{fontSize: 14, lineHeight: 1.1}}>
                      <br />
                      <br />
                      <img
                        src={forexSportLetterheadFooter}
                        alt='ForexSport Logo'
                        style={{width: '100%'}}
                      />
                  </p>
              </React.Fragment>
            )
            break
        case 49:
            // Forex Sport Ninja
            return (
              <React.Fragment>
                  <p style={{fontSize: 14, lineHeight: 1.1}}>
                      <br />
                      <br />
                      <img
                        src={forexSportLetterheadFooter}
                        alt='ForexSport Logo'
                        style={{width: '100%'}}
                      />
                  </p>
              </React.Fragment>
            )
            break;
        default:
            return (
              <React.Fragment>
                  <p style={{fontSize: 14, lineHeight: 1.1}}>
                      <br />
                      <br />
                      <img
                        src={forexWorldwideLetterheadFooter}
                        alt='Forex Worldwide Logo'
                        style={{width: '100%'}}
                      />
                  </p>
              </React.Fragment>
            )
    }
}