import LoginPage from '../views/Pages/LoginPage'
import LoginDirectPage from '../views/Pages/LoginDirectPage'
import RegisterPage from '../views/Pages/RegisterPage'
import MigratePage from '../views/Pages/MigratePage'

// @material-ui/icons
import PersonAdd from '@material-ui/icons/PersonAdd'
import Fingerprint from '@material-ui/icons/Fingerprint'
import VerifyEmailPage from '../views/Pages/VerifyEmailPage'
import IdentificationEditContainer from '../views/Dashboard/Identification/IdentificationEditContainer'
import RegistrationPage from '../views/Pages/RegistrationPage'
// import RateTickerVerticalBoxLarge from "../views/Pages/FxRateBox";
import CurrencyTools from '../views/Dashboard/CurrencyTools'

import languageObj_es_json from '../translations/es.json'
import languageObj_de_json from '../translations/de.json'
import languageObj_zh_json from '../translations/zh.json'
import languageObj_fr_json from '../translations/fr.json'
import languageObj_it_json from '../translations/it.json'
import languageObj_pt_json from '../translations/pt.json'
import DashboardIcon from '@material-ui/icons/Dashboard'
import TransferReceiptPageContainer from '../views/Dashboard/Transfers/TransferReceiptPage'
import ReceiptPageContainer from '../views/Pages/ReceiptPage'
import ReceiptPage from '../views/Pages/ReceiptPage'

import OpenTransferRemittanceInstructionsPage from '../views/Pages/OpenTransferRemittanceInstructionsPage'
import RemittancePageContainer from '../views/Pages/RemittancePage'
import PaymentRequestViewPagePrintContainer from "../views/Pages/PaymentRequestViewPageP";


const pagesRoutes = [
    {
        path: '/pages/register-page',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        invisible: true,
        icon: PersonAdd,
        component: RegisterPage,
    },
    // {
    //   path: "/pages/registration",
    //   name: "Register Page",
    //   short: "Register",
    //   mini: "RP",
    //   invisible: true,
    //   icon: PersonAdd,
    //   component: RegistrationPage
    // },
    {
        path: '/pages/register/:referral_code',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        invisible: true,
        icon: PersonAdd,
        component: RegisterPage,
    },
    {
        path: '/pages/register',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        icon: PersonAdd,
        component: RegisterPage,
    },
    {
        path: '/pages/migrate/:referral_code',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        invisible: true,
        icon: PersonAdd,
        component: MigratePage,
    },
    {
        path: '/pages/migrate/',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        invisible: true,
        icon: PersonAdd,
        component: MigratePage,
    },
    {
        path: '/pages/verify-email',
        name: 'Verify Email',
        short: 'Verify',
        mini: 'VE',
        invisible: true,
        icon: PersonAdd,
        component: VerifyEmailPage,
    },
    {
        path: '/pages/login/:referral_code',
        name: 'Login Page',
        short: 'Login',
        mini: 'LP',
        invisible: true,
        icon: Fingerprint,
        component: LoginPage,
    },
    {
        path: '/pages/login',
        name: 'Login Page',
        short: 'Login',
        mini: 'LP',
        icon: Fingerprint,
        component: LoginPage,
    },
    {
        path: '/pages/login-page',
        name: 'Login Page',
        short: 'Login',
        mini: 'LP',
        invisible: true,
        icon: Fingerprint,
        component: LoginPage,
    },
    {
        path: '/pages/login-direct',
        name: 'Login Page Direct',
        short: 'Login',
        mini: 'LP',
        invisible: true,
        icon: Fingerprint,
        component: LoginDirectPage,
    },
    {
        path: '/pages/economic-information',
        name: 'Economic Information',
        short: 'EconomicInformation',
        mini: 'EI',
        invisible: true,
        icon: Fingerprint,
        component: CurrencyTools,
    },
    {
        path: "/pages/receipt/:transfer_id",
        name: "Receipt",
        name_es: languageObj_es_json["icon.pastTransfers"],
        name_de: languageObj_de_json["icon.pastTransfers"],
        name_zh: languageObj_zh_json["icon.pastTransfers"],
        name_fr: languageObj_fr_json["icon.pastTransfers"],
        name_it: languageObj_it_json["icon.pastTransfers"],
        name_pt: languageObj_pt_json["icon.pastTransfers"],
        invisible: true,
        //mini: "CT",
        component: ReceiptPageContainer
    },
    {
        path: "/pages/remittance/:transfer_id",
        name: "Remittance",
        name_es: languageObj_es_json["icon.pastTransfers"],
        name_de: languageObj_de_json["icon.pastTransfers"],
        name_zh: languageObj_zh_json["icon.pastTransfers"],
        name_fr: languageObj_fr_json["icon.pastTransfers"],
        name_it: languageObj_it_json["icon.pastTransfers"],
        name_pt: languageObj_pt_json["icon.pastTransfers"],
        invisible: true,
        //mini: "CT",
        component: RemittancePageContainer
    },
    {
        path: "/pages/payment-requests/print/:transferPendingID",
        name: "PaymentRequestViewPrint",
        name_es: languageObj_es_json["icon.pastTransfers"],
        name_de: languageObj_de_json["icon.pastTransfers"],
        name_zh: languageObj_zh_json["icon.pastTransfers"],
        name_fr: languageObj_fr_json["icon.pastTransfers"],
        name_it: languageObj_it_json["icon.pastTransfers"],
        name_pt: languageObj_pt_json["icon.pastTransfers"],
        invisible: true,
        //mini: "CT",
        component: PaymentRequestViewPagePrintContainer
    },
    {
        path: '/pages/open-transfer/remittance-instructions/:transfer_id',
        name: 'Remittance Instructions',
        short: 'RemittanceInstructions',
        mini: 'RI',
        invisible: true,
        icon: Fingerprint,
        component: OpenTransferRemittanceInstructionsPage,
    },
    // {
    //   path: "/pages/fx-rates-box",
    //   name: "FX Rates Box",
    //   short: "FxRatesBox",
    //   mini: "EI",
    //   invisible: true,
    //   icon: Fingerprint,
    //   component: RateTickerVerticalBoxLarge
    // },
    {
        redirect: true,
        path: '/pages',
        pathTo: '/pages/login-page',
        name: 'Login Page',
    }
]

export default pagesRoutes
